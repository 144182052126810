import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Button, TextField, IconButton, Snackbar, Alert } from '@mui/material';
import { Add as AddIcon, Check as CheckIcon } from '@mui/icons-material';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import * as XLSX from 'xlsx';
import AWS from 'aws-sdk';
import { Import } from 'lucide-react';
import { awsExports } from '../../aws-exports';
import { styled } from '@mui/system';
import { ChevronDown, ChevronUp } from 'lucide-react';
import WelcomeHeader from './WelcomeHeader';

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { importNotificationsState } from '../../store/atoms';



// Mobile Header Component
const MobileHeader = ({ isOpen, setIsOpen }) => {
  return (
    <div className="md:hidden w-full bg-white flex items-center justify-between px-4 py-3">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-black hover:text-gray-900"
      >
        {/* Embedded SVG icon */}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M2 6.28572L2 17.7143C2 18.9767 3.11929 20 4.5 20L12 20L19.5 20C20.8807 20 22 18.9767 22 17.7143L22 6.28571C22 5.02335 20.8807 4 19.5 4L4.5 4C3.11929 4 2 5.02335 2 6.28572Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9 20L9 4" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

      <div className="absolute left-1/2 transform -translate-x-1/2">
        <img 
          src="/Logovarient2.svg" 
          alt="Findraiser Logo" 
          className="h-8"
        />
      </div>
      
      <div className="w-8" /> {/* Spacer for centering */}
    </div>
  );
};


const MatchFieldsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { headers: importedHeaders, data, requiredHeaders: initialRequiredHeaders, uploadTitle, fileName, tenantGroup } = location.state;

  const mandatoryFields = ['First Name', 'Last Name', 'Personal Email', 'Home City'];

  const [requiredHeaders, setRequiredHeaders] = useState(initialRequiredHeaders);
  const [matchedFields, setMatchedFields] = useState({});
  const [newFields, setNewFields] = useState({});
  const [availableFields, setAvailableFields] = useState(['', ...importedHeaders]);
  const [newFieldText, setNewFieldText] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [importNotification,setImportNotifications] = useRecoilState(importNotificationsState);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const initialMatched = {};
    requiredHeaders.forEach(header => {
      initialMatched[header] = '';
    });
    setMatchedFields(initialMatched);
  }, [requiredHeaders]);

  const validateField = (requiredField, selectedValue) => {
    if (!selectedValue) return ''; // Skip validation if field is ignored
    const sampleData = data.slice(0, 10).map(row => row[importedHeaders.indexOf(selectedValue)]);
    
    // Helper function to check if a value is empty or undefined
    const isEmpty = (value) => {
      if (value === undefined || value === null) return true;
      if (typeof value === 'string') return value.trim() === '';
      if (typeof value === 'number') return isNaN(value);
      return false;
    };
  
    // Filter out empty values before validation
    const nonEmptyData = sampleData.filter(item => !isEmpty(item));
  
    // If all values are empty, return no error
    if (nonEmptyData.length === 0) return '';
  
    switch (requiredField) {
     
      case 'Home State/Province':
      case 'Home City':
        console.log('non empty data: ' + JSON.stringify(nonEmptyData));
        return nonEmptyData.every(item =>
          typeof item === 'string' &&
          /^(?:[a-zA-Z]+(?:\.)?(?:[\s'-][a-zA-Z]+)*|[a-zA-Z]+(?:[\s'-][a-zA-Z]+)*)$/.test(item) &&
          item.length >= 2 && 
          item.length <= 100
        ) ? '' 
        : `${requiredField} should contain only alphabetic characters with optional spaces, hyphens, apostrophes, or periods, and be between 2 and 100 characters long.`;
      
      
      case 'Country':
      case 'Occupation':
      case 'Job Title':
      //case 'Contact Type (Remedy PAC)':
      case 'Designation':
      case 'Type of Contact':  
      case 'Period':
        return nonEmptyData.every(item => typeof item === 'string' && /^[a-zA-Z]+(,[a-zA-Z]+)*$/.test(item)) ? '' 
          : `${requiredField} should contain only characters and optional commas when present`;
      case 'Home Zip/Postal':
        return nonEmptyData.every(item => {
          if (item === '' || item === null || item === undefined) {
            // Allow empty cells
            return true;
          }
                
          const zipString = typeof item === 'number' ? item.toString() : item;
        
          // Validate US ZIP code (5 digits or 5+4 format)
          const isUSZip = /^\d{5}(-\d{4})?$/.test(zipString);
        
          return isUSZip;
        }) 
        ? '' 
        : 'Zip code should be a valid US ZIP code (e.g., 12345 or 12345-6789).';
        
 
        case 'First Name':
        case 'Last Name':
        case 'Middle Name':
        case 'State House District':
        case 'State Senate District': 
        case 'Employer':
        case 'Contact Type (Remedy PAC)':
          return nonEmptyData.every(item => 
            typeof item === 'string' && /^[a-zA-Z\s,.'-]+$/.test(item) && item.trim() !== ''
          )
          ? '' 
          : `${requiredField} should contain only alphabetic characters, spaces, commas, periods, apostrophes, or hyphens when present.`;
      case 'Personal Email':
          return nonEmptyData.every(item => typeof item === 'string' && 
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(item)
            )
            ? '' 
            : 'Personal Email should be a valid email address';
      // case 'Employer':
      //       return nonEmptyData.every(item => 
      //           typeof item === 'string' && /^[a-zA-Z\s-]+$/.test(item)
      //         )
      //         ? '' 
      //         : 'Employer should contain only letters, spaces, or hyphens';
      case 'Date Received':
        return nonEmptyData.every(item => {
          if (typeof item !== 'string') return true; // Allow empty fields
            return /^[0-9/\\\- ]*$/.test(item);
          })
          ? ''
          : 'Date Received should only contain numbers, /. Format can be MM/DD/YY or MM/DD/YYYY';
            
        
      case 'Amount':
      case 'Home Phone':
              return nonEmptyData.every(item => {
                if (typeof item === 'number') {
                  return !isNaN(item);
                }
                if (typeof item === 'string') {
                  // Trim any extra spaces and ensure no non-numeric characters (except optional decimal) are present
                  const sanitizedItem = item.trim().replace(/[$,]+/g, '');
                  return /^-?\d+(\.\d+)?$/.test(sanitizedItem);
                }
                return false;
              })
              ? ''
              : 'It should be a valid number only without any special characters';
      default:
        return '';
    }
  };

  const handleMatchChange = (requiredField, selectedValue) => {
     // Check if the field is mandatory and the user is trying to ignore it
     if (mandatoryFields.includes(requiredField) && selectedValue === '') {
      setSnackbarMessage(`${requiredField} is mandatory and cannot be ignored.`);
      setOpenSnackbar(true);
      return;
    }

    // Check if the selected value is already matched to another field
    const alreadyMatchedField = Object.entries(matchedFields).find(
      ([key, value]) => value === selectedValue && key !== requiredField
    );

    if (alreadyMatchedField && selectedValue !== '') {
      setSnackbarMessage(`This column is already matched to ${alreadyMatchedField[0]}`);
      setOpenSnackbar(true);
      return;
    }

    setMatchedFields(prev => ({
      ...prev,
      [requiredField]: selectedValue
    }));

    if (selectedValue && selectedValue !== '') {
      const error = validateField(requiredField, selectedValue);
      if (error) {
        setValidationErrors(prev => ({ ...prev, [requiredField]: error }));
        setSnackbarMessage(error);
        setOpenSnackbar(true);
      } else {
        setValidationErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[requiredField];
          return newErrors;
        });
      }
    } else {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[requiredField];
        return newErrors;
      });
    }
  };


  // const handleCreateNewField = (requiredField) => {
  //   if (newFields[requiredField]) {
  //     if (newFieldText[requiredField] && !availableFields.includes(newFieldText[requiredField])) {
  //       setAvailableFields(prev => [...prev, newFieldText[requiredField]]);
  //       handleMatchChange(requiredField, newFieldText[requiredField]);
  //     }
  //     setNewFields(prev => ({ ...prev, [requiredField]: false }));
  //     setNewFieldText(prev => ({ ...prev, [requiredField]: '' }));
  //   } else {
  //     setNewFields(prev => ({ ...prev, [requiredField]: true }));
  //   }
  // };

  const handleCreateNewField = (requiredField) => {
    if (newFields[requiredField]) {
      if (newFieldText[requiredField] && !requiredHeaders.includes(newFieldText[requiredField])) {
        // Add the new field as a required header
        setRequiredHeaders(prev => [...prev, newFieldText[requiredField]]);
        
        // Initialize the matched field for the new header
        setMatchedFields(prev => ({
          ...prev,
          [newFieldText[requiredField]]: ''
        }));
      }
      setNewFields(prev => ({ ...prev, [requiredField]: false }));
      setNewFieldText(prev => ({ ...prev, [requiredField]: '' }));
    } else {
      setNewFields(prev => ({ ...prev, [requiredField]: true }));
    }
  };

  const handleNewFieldTextChange = (requiredField, newValue) => {
    setNewFieldText(prev => ({
      ...prev,
      [requiredField]: newValue
    }));
  };

  // const handleFinishUpload = async () => {
  //   // Create a map of imported headers to their column indices
  //   const headerIndexMap = importedHeaders.reduce((acc, header, index) => {
  //     acc[header] = index;
  //     return acc;
  //   }, {});

  //   const errors = requiredHeaders.map(header => validateField(header, matchedFields[header])).filter(Boolean);
  //   if (errors.length > 0) {
  //     setValidationErrors(errors.join('. '));
  //     setOpenSnackbar(true);
  //     return;
  //   }

  //   // Restructure the data
  //   const restructuredData = [requiredHeaders];

  //   data.forEach(row => {
  //     const newRow = requiredHeaders.map(requiredHeader => {
  //       const matchedField = matchedFields[requiredHeader] || newFields[requiredHeader];
  //       if (matchedField && headerIndexMap.hasOwnProperty(matchedField)) {
  //         return row[headerIndexMap[matchedField]];
  //       } else if (newFields[requiredHeader]) {
  //         // For newly created fields, add an empty string
  //         return '';
  //       }
  //       return ''; // Empty string for unmatched or ignored fields
  //     });
  //     restructuredData.push(newRow);
  //   });

  const updateNotifications = (success, header, message, timestamp) => {
    setImportNotifications((prevNotifications) => {
      const newNotification = {
        id: prevNotifications.length + 1,
        success,
        title: header,
        description: message,
        timestamp
      };
      const updatedNotifications = [...prevNotifications, newNotification];
      
      // Store in local storage
      localStorage.setItem('importNotifications', JSON.stringify(updatedNotifications));
      
      return updatedNotifications;
    });
  };


  const handleFinishUpload = async () => {
// Check if all mandatory fields are matched
    const unmatchedMandatoryFields = mandatoryFields.filter(field => !matchedFields[field]);
    if (unmatchedMandatoryFields.length > 0) {
      setSnackbarMessage(`Please match the following mandatory fields: ${unmatchedMandatoryFields.join(', ')}`);
      setOpenSnackbar(true);
      return;
    }

    // Create a map of imported headers to their column indices
    const headerIndexMap = importedHeaders.reduce((acc, header, index) => {
      acc[header] = index;
      return acc;
    }, {});

    const errors = requiredHeaders.map(header => validateField(header, matchedFields[header])).filter(Boolean);
    if (errors.length > 0) {
      setValidationErrors(errors.join('. '));
      setOpenSnackbar(true);
      return;
    }

    // Restructure the data
    const restructuredData = [requiredHeaders];

    data.forEach(row => {
      const newRow = requiredHeaders.map(requiredHeader => {
        const matchedField = matchedFields[requiredHeader];
        if (matchedField && headerIndexMap.hasOwnProperty(matchedField)) {
          return row[headerIndexMap[matchedField]];
        } else {
          // For newly created fields or unmatched fields, add an empty string
          return '';
        }
      });
      restructuredData.push(newRow);
    });

    // Create worksheet from restructured data
    const ws = XLSX.utils.aoa_to_sheet(restructuredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const csvContent = XLSX.utils.sheet_to_csv(ws);

    // Upload to S3
    try {
      const s3 = new AWS.S3({
        accessKeyId: 'AKIA2EJNKTWOV7X2F4VC',
        secretAccessKey: '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',
        // region: 'us-east-1'
        region: awsExports.S3.region,
        //region: 'us-east-2',
      });

      const formattedDateTime = getFormattedDateTimeUTC();
      //const folderPath = 'ClientUpload/';
      const folderPath = `Tenants/${tenantGroup}/ClientUpload/`;
      console.log(`folder path is : ${folderPath}`);
      const fileExtension = ".csv";
      const baseFilename = fileName.split('.').slice(0, -1).join('.').replace(/\s+/g, '_') || "import";
      const newFilename = `${baseFilename}_${formattedDateTime}${fileExtension}`;

        // Check if the folder exists
    const folderCheckParams = {
      Bucket: awsExports.S3.bucket,
      Prefix: folderPath,
      MaxKeys: 1
    };

    const folderCheckData = await s3.listObjectsV2(folderCheckParams).promise();
    
    // If the folder doesn't exist, create it
    // if (folderCheckData.Contents.length === 0) {
    //   const createFolderParams = {
    //     Bucket: awsExports.S3.bucket,
    //     Key: folderPath,
    //     Body: ''
    //   };
    //   await s3.putObject(createFolderParams).promise();
    //   console.log(`Created folder: ${folderPath}`);
    // }

    // Upload the file
    const uploadParams = {
      Bucket: awsExports.S3.bucket,
      Key: `${folderPath}${newFilename}`,
      Body: csvContent,
      ContentType: 'text/csv'
    };

    await s3.upload(uploadParams).promise();
    console.log(`File uploaded successfully to ${folderPath}${newFilename}`);
    updateNotifications(true,'Import Notification', `File "${fileName}" imported successfully`, new Date().getTime());

    // Navigate back to the import page with success state
    navigate('/import', { 
      state: { 
        success: true, 
        message: `Import file to create column in database successfully completed`,
        uploadedFile: {
          name: fileName,
          title: uploadTitle,
          dateUploaded: new Date().toLocaleDateString(),
          progress: 100,
          total: `${(new Blob([csvContent]).size / (1024 * 1024)).toFixed(2)} MB`,
          email: '-',
          mobile: '-'
        }
      } 
    });
    
  } catch (error) {
    updateNotifications(false,'Import Notification', `Import failed for file "${fileName}". Please check your file and try again.`, new Date().getTime());
    console.error('Error uploading to S3:', error);
    setValidationErrors('An error occurred while uploading the file. Please try again.');
    setOpenSnackbar(true);
  }
};

  const handleCancelUpload = () => {
    navigate('/import');
  };

  const getFormattedDateTimeUTC = () => {
    const now = new Date();
    return now.toISOString().replace(/[:\-]|\.\d{3}/g, '');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const [showAllRows, setShowAllRows] = useState(false);

  const toggleShowAllRows = () => {
    setShowAllRows(!showAllRows);
  };

  const visibleHeaders = showAllRows ? requiredHeaders : requiredHeaders.slice(0, 6);


 // Custom styled components
 const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
}));

// Custom styled components
const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
  
}));

const StyledTableCell = styled(TableCell)({
  border: 'none',
  color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  paddingLeft: '0',
  '&:last-child': {
    paddingRight: 0,
  },
});

const StyledTableHeaderCell = styled(TableCell)({
  border: 'none',
  color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
  fontFamily: 'Inter, sans-serif',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  paddingLeft: '0',
  '&:last-child': {
    paddingRight: '0',
    textAlign: 'left',
    paddingLeft: '34px', // Align with the content below
  },
});

const ViewMoreButton = styled(Button)({
  display: 'flex',
  padding: '10px',
  alignItems: 'center',
  gap: '10px',
  color: '#000',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
});

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '0',
    paddingRight: '6px', // Reduced padding
    color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});
const DropdownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" fill="none" style={{ flexShrink: 0, marginLeft: '1px' }}> {/* Reduced margin */}
    <path d="M7 4L4 1L1 4" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 12L4 15L7 12" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

const TickIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M20 6L9 17L4 12" stroke="#40C5AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

return (
  <div className="flex h-screen overflow-hidden">
    {/* Main content container */}
    <div className="flex-1 flex flex-col overflow-hidden">
      
      {/* Welcome Header */}
      <div className="w-full md:w-auto flex-shrink-0">
        <WelcomeHeader />
      </div>

      {/* Scrollable content area */}
      <div className="flex-1 overflow-y-auto">
        <main className="p-8" style={{ paddingLeft: '40px' }}>
          {/* Page Header */}
          <div className="mb-6">
            <h1 className="text-2xl font-bold mb-2">Match Fields</h1>
            <p className="text-base max-w-2xl">
              Please upload file below to create column in database
            </p>
          </div>

          {/* Main content section */}
          <div className="w-full max-w-[80%] pt-5 border-t border-[#D9D9D9]">
            {/* Validation Errors Alert */}
            {Object.keys(validationErrors).length > 0 && (
              <Alert severity="error" className="mb-5 w-full">
                Please correct the following errors:
                <ul>
                  {Object.entries(validationErrors).map(([field, error]) => (
                    <li key={field}>{`${field}: ${error}`}</li>
                  ))}
                </ul>
              </Alert>
            )}

            <TableContainer component={Paper} style={{ width: '100%', boxShadow: 'none' }}>
              <Table className="text-xs md:text-sm"> {/* Smaller font for mobile */}
                <TableHead>
                  <StyledTableRow2>
                    <StyledTableHeaderCell style={{ width: '40%' }}>Required Column Name</StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '60%', paddingRight: '12px' }}> {/* Extra padding */}
                      Match With Field
                    </StyledTableHeaderCell>
                  </StyledTableRow2>
                  <tr>
                    <td colSpan={2} style={{ padding: 0 }}>
                      <hr style={{ margin: '0', border: 'none', borderTop: '1px solid #D9D9D9' }} />
                    </td>
                  </tr>
                </TableHead>
                <TableBody>
                  {visibleHeaders.map((header, index) => (
                    <React.Fragment key={header}>
                      <StyledTableRow>
                        <StyledTableCell>
                          {header}{mandatoryFields.includes(header) && ' *'}
                        </StyledTableCell>
                        <StyledTableCell>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '24px', marginRight: '8px', display: 'flex', justifyContent: 'flex-end' }}>
                              {matchedFields[header] && !validationErrors[header] && <TickIcon />}
                            </div>
                            <StyledSelect
                              value={matchedFields[header] || ''}
                              onChange={(e) => handleMatchChange(header, e.target.value)}
                              displayEmpty
                              renderValue={(selected) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    color: selected ? '#000' : 'inherit',
                                    fontWeight: selected ? 700 : 'inherit',
                                    gap: '8px',  // Added gap for space between text and icon
                                  }}
                                >
                                  <span>{selected === '' ? (mandatoryFields.includes(header) ? 'Select a Field' : 'Ignore') : selected}</span>
                                  <DropdownIcon />
                                </div>
                              )}
                              IconComponent={() => null}
                              error={!!validationErrors[header]}
                              style={{ flexGrow: 1, minWidth: '120px' }} // Ensures a minimum width
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    padding: '4px',
                                    borderRadius: '14px',
                                    minWidth: '180px',
                                    maxWidth: '220px',
                                  },
                                },
                              }}
                            >
                              {!mandatoryFields.includes(header) && (
                                <MenuItem value="" style={{ justifyContent: 'flex-start', width: '100%' }}>
                                  Ignore
                                </MenuItem>
                              )}
                              {availableFields
                                .filter((field) => field !== '')
                                .map((field) => (
                                  <MenuItem
                                    key={field}
                                    value={field}
                                    disabled={Object.values(matchedFields).includes(field) && matchedFields[header] !== field}
                                    style={{ width: '100%' }}
                                  >
                                    {field}
                                  </MenuItem>
                                ))}
                            </StyledSelect>
                          </div>
                          {validationErrors[header] && (
                            <p className="text-red-500 text-xs mt-1">{validationErrors[header]}</p>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                      {index < visibleHeaders.length - 1 && (
                        <tr>
                          <td colSpan={2} style={{ padding: 0 }}>
                            <hr style={{ margin: '0', border: 'none', borderTop: '1px solid #D9D9D9' }} />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

              {requiredHeaders.length > 6 && (
                <ViewMoreButton onClick={toggleShowAllRows}>
                  {showAllRows ? <ChevronUp size={24} color="#40C5AF" /> : <ChevronDown size={24} color="#40C5AF" />}
                  <span className='text-[#000] font-inter text-[1rem] font-normal leading-[120%] normal-case'>{showAllRows ? 'View less' : 'View more'}</span>
                </ViewMoreButton>
              )}
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: '20px',
              }}>
                <Button
                  onClick={handleCancelUpload}
                  style={{
                    display: 'flex',
                    padding: '10px 14px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    borderRadius: '7px',
                    border: '1px solid #EBAE12',
                    background: '#FFF',
                    color: '#EBAE12',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '120%',
                    marginRight: '16px',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleFinishUpload}
                  style={{
                    display: 'flex',
                    padding: '10px 14px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    borderRadius: '7px',
                    background: '#EBAE12',
                    color: '#FFF',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '120%',
                  }}
                >
                  Finish Upload
                  </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  </div>
);
};
export default MatchFieldsPage;