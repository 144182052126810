import {
  CloudWatchLogsClient,
  CreateLogGroupCommand,
  CreateLogStreamCommand,
  DescribeLogStreamsCommand,
  PutLogEventsCommand,
} from "@aws-sdk/client-cloudwatch-logs";
import {
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
} from "@aws-amplify/auth";


async function configureAWSCredentials() {
  try {
    const { credentials } = await fetchAuthSession();
    //console.log('credentials: '+JSON.stringify(credentials));
    return credentials;
  } catch (error) {
    console.error("Error getting AWS credentials:", error);
    throw error;
  }
}

async function getUserDetails() {
  try {
    const user = await getCurrentUser();
    const userAttributes = await fetchUserAttributes();

    return {
      username: user.username,
      userId: userAttributes.sub || null,
      email: userAttributes.email || null,
      // Add any other user attributes you want to include
    };
  } catch (error) {
    console.error("Error getting user details:", error);
    return null;
  }
}

async function logEvent(message) {
  const credentials = await configureAWSCredentials();
  const userDetails = await getUserDetails();

  const cloudwatchlogsClient = new CloudWatchLogsClient({
    region: "us-east-1", // Replace with your region
    credentials: credentials,
  });

  const logGroupName = "FindRaiserUserActivity";
  const logStreamName = "UserActivityStream";
  //console.log("message:" + JSON.stringify(message))

  async function getSequenceToken(logGroupName, logStreamName) {
    const describeParams = {
      logGroupName,
      logStreamNamePrefix: logStreamName,
    };
    const data = await cloudwatchlogsClient.send(
      new DescribeLogStreamsCommand(describeParams)
    );
    if (data.logStreams && data.logStreams.length > 0) {
      return data.logStreams[0].uploadSequenceToken;
    }
    return null;
  }

    // try {
    //     await cloudwatchlogsClient.send(new CreateLogGroupCommand({ logGroupName }));
    // } catch (err) {
    //     if (err.name !== 'ResourceAlreadyExistsException') {
    //         console.error('Error creating log group:', err);
    //         return;
    //     }
    // }

    // try {
    //     await cloudwatchlogsClient.send(new CreateLogStreamCommand({ logGroupName, logStreamName }));
    // } catch (err) {
    //     if (err.name !== 'ResourceAlreadyExistsException') {
    //         console.error('Error creating log stream:', err);
    //         return;
    //     }
    // }

  const sequenceToken = await getSequenceToken(logGroupName, logStreamName);

  // Combine user details with the message
  const logMessage = {
    user: userDetails,
    message: message,
    timestamp: new Date().toISOString(),
  };

  const params = {
    logGroupName,
    logStreamName,
    logEvents: [
      {
        message: JSON.stringify(logMessage),
        timestamp: new Date().getTime(),
      },
    ],
    sequenceToken,
  };

  try {
    const data = await cloudwatchlogsClient.send(
      new PutLogEventsCommand(params)
    );
    //console.log('Logged successfully');
  } catch (err) {
    if (err.name === "InvalidSequenceTokenException") {
      params.sequenceToken = err.expectedSequenceToken;
      try {
        const data = await cloudwatchlogsClient.send(
          new PutLogEventsCommand(params)
        );
        console.log("Logged successfully after retry:", data);
      } catch (retryErr) {
        console.error(
          "Error logging event to CloudWatch after retry:",
          retryErr
        );
      }
    } else {
      console.error("Error logging event to CloudWatch:", err);
    }
  }
}

export { configureAWSCredentials, logEvent };
