import { useRecoilValue } from "recoil";
import { firstNameState, displayRoleState } from "../../store/atoms";


const WelcomeHeader = () => {
    const firstName = useRecoilValue(firstNameState);
    const displayRole = useRecoilValue(displayRoleState);
    return (
      <header className="flex flex-wrap gap-2.5 items-end self-stretch px-10 py-5 border-b border-solid border-b-zinc-300 max-md:px-5">
        <h1 className="text-xl leading-6 text-black">
          <span className="text-base font-inter leading-5">Welcome,</span>
          <br />
          <span className="font-inter font-bold">{firstName}</span>
        </h1>
        {displayRole && (
        <span className="overflow-hidden gap-2.5 px-1.5 py-1 text-[13px] font-inter font-bold leading-tight text-emerald-400 bg-emerald-50 rounded-lg border border-emerald-100 border-solid">
          {displayRole}
        </span>
        )}

      </header>
    );
  };

export default WelcomeHeader;