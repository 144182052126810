import React, { useState, useEffect } from 'react';
import { Bell, Users, FileText, Target, Upload, Monitor, LogOut, HelpCircle, Settings, Heart, Download } from 'lucide-react';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import WelcomeHeader from './WelcomeHeader';
import { useMediaQuery } from '@mui/material';

const HelpPage = () => {
  const [loading, setLoading] = useState(true);
  const [sidebarItems, setSidebarItems] = useState([]);
  const [expandedSections, setExpandedSections] = useState({
    Dashboard: false,
    UserManagement: false,
    QueryManagement: false,
    TargetedSearch: false,
    FavoriteQueries: false,
    ImportData: false
  });

  const isMobile = useMediaQuery('(max-width:768px)');

  // useEffect(() => {
  //   // Simulate loading delay
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1500);
  //   return () => clearTimeout(timer);
  // }, []);

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    // Simulating API call
    setTimeout(() => {
      setSidebarItems([
        {
          id: 'Dashboard',
          title: 'Dashboard',
          description: 'Provides an application dashboard for quick overview and fast querying capabilities.'
        },
        {
          id: 'UserManagement',
          title: 'User Management',
          description: 'Used for creating and managing users for particular groups. Admins can control user access and permissions.'
        },
        {
          id: 'QueryManagement',
          title: 'Query Management',
          description: 'Manage certain queries for user visibility, such as pinned prompts. Helps in organizing and prioritizing important queries.'
        },
        {
          id: 'TargetedSearch',
          title: 'Targeted Search',
          description: 'Acts as a chat interface with the AI, allowing users to perform targeted searches and receive intelligent responses.'
        },
        {
          id: 'FavoriteQueries',
          title: 'My Favorite Queries',
          description: 'Access and manage your saved queries for quick reference and reuse.'
        },
        {
          id: 'ImportData',
          title: 'Import Data',
          description: 'Used to import CSV files through the UI. Includes an import tool to help users import files with correct column headers according to guidelines.'
        }
      ]);
      setLoading(false);
    }, 1000);
  }, []);


  // const sidebarItems = [
  //   {
  //     id: 'Dashboard',
  //     title: 'Dashboard',
  //     description: 'Provides an application dashboard for quick overview and fast querying capabilities.'
  //   },
  //   {
  //     id: 'UserManagement',
  //     title: 'User Management',
  //     description: 'Used for creating and managing users for particular groups. Admins can control user access and permissions.'
  //   },
  //   {
  //     id: 'QueryManagement',
  //     title: 'Query Management',
  //     description: 'Manage certain queries for user visibility, such as pinned prompts. Helps in organizing and prioritizing important queries.'
  //   },
  //   {
  //     id: 'TargetedSearch',
  //     title: 'Targeted Search',
  //     description: 'Acts as a chat interface with the AI, allowing users to perform targeted searches and receive intelligent responses.'
  //   },
  //   {
  //     id: 'FavoriteQueries',
  //     title: 'My Favorite Queries',
  //     description: 'Access and manage your saved queries for quick reference and reuse.'
  //   },
  //   {
  //     id: 'ImportData',
  //     title: 'Import Data',
  //     description: 'Used to import CSV files through the UI. Includes an import tool to help users import files with correct column headers according to guidelines.'
  //   }
  // ];

  const LoadingSkeleton = () => (
    <div className="space-y-4">
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <div key={item} className="rounded-[14px] bg-gray-100 h-16 animate-pulse" />
      ))}
    </div>
  );

  const handleDownloadPDF = () => {
    console.log('Downloading PDF...');
  };

  const themeStyles = {
    backgroundColor: '#FFF',
    color: '#000',
    minHeight: '100vh',
    display: "flex",
    width: "100%"
  };

  return (
     <div style={themeStyles}>
      {/* <Sidebar_v2 /> */}
      {loading ? (
        <div className="flex-1 flex items-center justify-start w-screen h-screen p-8">
          <img src="/NotificationLoading.svg" alt="Loading..." className="h-full w-auto object-contain"/>
        </div>
      ) : (
        <div className='flex-1 flex flex-col overflow-hidden h-screen md:max-w-none max-w-[440px]'>
        <WelcomeHeader />
        <main className="flex flex-col h-screen items-start gap-10 p-10 overflow-y-auto">
          {/* Help Center Header Section */}
          <div className="flex flex-col md:h-auto h-[410px] gap-[40px] w-full">
            <div className="flex justify-between items-center self-stretch">
              <div className="flex flex-col items-start gap-1.5 flex-1">
                <p className="self-stretch text-black font-inter text-[1.5625rem] font-bold leading-[120%]">
                  Help Center
                </p>
                <p className="text-black font-inter text-base font-normal leading-[120%]">
                  Welcome to the Help Center! Here you can find information about using the app and its various features.
                </p>
              </div>
            </div>
          </div>
           {/* Main Content Section */}
           <div className="flex flex-col items-start gap-2.5 self-stretch border-t border-[#D9D9D9]">
            <div className="flex md:flex-row flex-col py-5 items-start gap-5 self-stretch">
              {/* Product Information Section */}
              <div className="flex md:w-[18.75rem] w-full flex-col items-start gap-5 self-stretch">
                <p className="text-black font-inter text-base font-bold leading-[120%]">
                  Product information
                </p>
                <div className="flex flex-col items-start gap-20 self-stretch">
                  <div className="flex flex-col items-start gap-2.5 self-stretch">
                    <p className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%]">
                      Didn't find the answer to your question?
                    </p>
                    <div className="flex justify-center items-center">
                      <p className="text-black font-inter text-base font-normal leading-[120%]">
                        Download <span className="text-[#EBAE12] underline">PDF</span> for more information and guidelines.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start gap-2.5 self-stretch">
                    <p className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%]">
                      General contact
                    </p>
                    <div className="flex flex-col items-start gap-2.5 self-stretch">
                      <p className="text-black font-inter text-base font-normal leading-[120%]">hello@findraiser.com</p>
                      <p className="text-black font-inter text-base font-normal leading-[120%]">830.223.0333</p>
                    </div>
                  </div>
                </div>
              </div>
               {/* Sidebar Navigation Section */}
              <div className={`flex flex-col md:w-auto ${isMobile ? 'w-full' : 'w-[400px]'} items-start gap-5 md:flex-1`}>
                <p className="text-black font-inter text-base font-normal leading-[120%] md:px-0 ">
                  Sidebar Navigation
                </p>
                
                {/* Container for tab boxes with consistent padding */}
                <div className="flex flex-col w-full gap-5 md:px-0 ">
                  {sidebarItems.map((item) => (
                    <div
                      key={item.id}
                      className={`
                        flex flex-col items-start self-stretch
                        md:p-4 p-5
                        md:gap-4 gap-5
                        rounded-[14px] border border-[#D9D9D9] bg-[#F2F2F2]
                        ${expandedSections[item.id] ? 'md:gap-2' : ''}
                      `}
                    >
                      <button
                        onClick={() => toggleSection(item.id)}
                        className="flex justify-between items-center w-full"
                      >
                        <p className="text-black font-inter text-[1.5625rem] font-normal leading-[120%]">
                          {item.title}
                        </p>
                        <div className="flex items-center gap-2.5 p-2.5">
                          <img
                            src={expandedSections[item.id] ? "/minushs.png" : "/plushs.png"}
                            alt={expandedSections[item.id] ? "collapse" : "expand"}
                            className="w-[42px] h-[42px]"
                          />
                        </div>
                      </button>
                      
                      {expandedSections[item.id] && (
                        <div className="flex flex-col items-start gap-5 self-stretch">
                          <p className="text-black font-inter text-base font-normal leading-[120%]">
                            {item.description}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      )}
    </div>
  );
};

export default HelpPage;
