import React, { useRef, useState, useEffect, useContext } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { conversationState, responseErrorState  } from "../../store/atoms";
//import { Amplify } from 'aws-amplify';
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { MyContext } from "../../Context/MyContext";
import { logEvent } from '../../cloudwatchLogger';
import {MoveUpRight} from 'lucide-react';
import useLambda from '../hooks/useLambda';
import { awsExports } from '../../aws-exports';
import AWS from 'aws-sdk';
import Sparkles from "./Sparkles";
import { getCurrentUser, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import {jwtTokenState} from '../../store/atoms';
//Amplify.configure(awsExports);
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';

import {
  conversationIdState
} from '../../store/atoms';

const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  //console.log('action is : '+action);
  const params = {
    FunctionName: lambdaFunctionName,
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    //console.log('result from the lambda is: '+JSON.stringify(result.data));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};



const DropdownItem = ({ text, onClick }) => (
  <div 
    className="w-full hover:bg-gray-100 cursor-pointer rounded-md transition-colors duration-200"
    onClick={onClick}
  >
    <div className="flex items-center p-3">
      <MoveUpRight className="w-4 h-4 mr-3 text-gray-600" />
      <span className="text-sm font-inter">{text}</span>
    </div>
    {/* <button onClick={(e) => { e.stopPropagation(); onRemove(); }} className="text-yellow-500 hover:text-yellow-700">
      Remove
    </button> */}
  </div>
);

const PromptBar = ({ selectedQuery }) => {
  const [selectedManagedQuery, setSelectedManagedQuery] = useState('');
  const [conversation, setConversation] = useRecoilState(conversationState);
  const [conversationId, setConversationId] = useRecoilState(conversationIdState);
  //console.log('conversation in prompt bar: '+conversation.length);
  const [inputQuestion, setInputQuestion] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localTranscript, setLocalTranscript] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { value, setValue, isDarkTheme } = useContext(MyContext);
  const [error, setError] = useState(null);
  //const [logEvents, setLogEvents] = useRecoilState(logEventsState);
  const [firstName, setFirstName] = useState("");
  const {configureLambda, decodeToken,getJWT } = useLambda();
  const [responseError, setResponseError] = useRecoilState(responseErrorState);

  const [lambdaFunctionName] = useState(awsExports.Lambda.queryManagement);
  const [tenantGroup, setTenantGroup] = useState([]);
  const [jwtToken, setJwtToken] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);
  const [sub, setSub] = useState('');
  const isMobile = useMediaQuery('(max-width:768px)');

  const [managedQueries, setManagedQueries] = useState([]);


  useEffect(() => {
    const fetchTokenAndSub = async () => {
      try {
        const token = await getJWT();
        await configureLambda(token);
        setJwtToken(token);
        const decodedToken = decodeToken(token);
        const tenantgroup = decodedToken['cognito:groups'];
        let filteredGroups = tenantgroup.includes("Tenants") ? tenantgroup.filter(group => group !== 'Tenants') : [...tenantgroup];
        setTenantGroup(filteredGroups);
        const cognitoRoles = decodedToken['cognito:roles'];
        if (cognitoRoles) {
          setIsSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
          setIsTenantAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
        }
        setSub(decodedToken['sub']);
      } catch (error) {
        console.error('Failed to fetch token:', error);
        logEvent({ eventType: 'fetchTokenError', details: `Error fetching token: ${error.message}` });
      }
    };

    fetchTokenAndSub();
  }, []);

  useEffect(() => {
    const fetchSavedQueries = async () => {
      if (!sub) return;
      try {
        const lambda = new AWS.Lambda();
        const data = await invokeLambda('getSavedQueries', { 'created_by':sub }, lambda, lambdaFunctionName);
        // console.log('managed queries for saved is: '+JSON.stringify(data));
        setManagedQueries(data);
      } catch (error) {
        console.error('Error fetching saved queries:', error);
      }
    };

    const fetchUserSavedQueries = async () => {
      //console.log("inside fetch user saved queries");
      if (!tenantGroup) return;
      try {
        //console.log(`tenant group inside fetch user group is : ${tenantGroup}`);
        const lambda = new AWS.Lambda();
        const data = await invokeLambda('getUserSavedQueries', { 'groupname':tenantGroup[0] }, lambda, lambdaFunctionName);
        //console.log('managed queries for user-saved is: '+JSON.stringify(data));
        setManagedQueries(data);
      } catch (error) {
        console.error('Error fetching saved queries:', error);
      }
    };

    if (isSuperAdmin || isTenantAdmin) {
      fetchSavedQueries();
    } else if (tenantGroup.length > 0) {
      fetchUserSavedQueries();
    }
  }, [sub, isSuperAdmin, isTenantAdmin, tenantGroup]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        //console.log(`user atttributes are: ${JSON.stringify(userAttributes)}`);
        setFirstName(userAttributes.given_name);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };
  
    fetchUserName();
  }, []);
   
  const apiUrl = awsExports.API.apiurl;

  const updateUserActivity = async (queryText) => {
    try {
        const attributes = await fetchUserAttributes();
        
        // Get current date and existing query count
        const today = new Date().toISOString().split('T')[0];
        // Handle null/undefined cases
        const lastQueryDate = attributes['custom:lastQueryDate'] || '';
        const queryCount = attributes['custom:dailyQueryCount'];
        
        // Calculate new query count
        let newQueryCount = 1;
        if (lastQueryDate === today) {
          // Use 0 as fallback if queryCount is null/undefined or not a valid number
          newQueryCount = parseInt(queryCount || '0', 10) + 1;
        }
        // Update user attributes
        await updateUserAttributes({
          userAttributes: {
            'custom:lastQuery': queryText,
            'custom:lastQueryTime': Date.now().toString(),
            'custom:lastQueryDate': today,
            'custom:dailyQueryCount': newQueryCount.toString()
          }
        });
    } catch (error) {
        console.error('Error updating user attributes:', error);
    }
};

  const handleInputChange = (e) => {
    setInputQuestion(e.target.value);
    setError(null); // Clear error on new input
  };

  const handleQueryClick = (query) => {
    setSelectedManagedQuery(query);
    logEvent({ eventType: 'querySelected', details: `Selected query: ${query}` });
  };

  const generateAnswer = async (prompt) => {
    // Get the JWT token
    const tokenJWT = await getJWT();
    //const tokenJWT = useRecoilValue(jwtTokenState)
    // console.log('token is: ' + tokenJWT);
   
    const postData = { 
      user_prompt: prompt,
      token: tokenJWT,
      conversation_id: conversationId,
    };
   
    // try {
    //   // Make the API request with both Authorization and auth-token headers
    //   const response = await axios.post(apiUrl, postData, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${tokenJWT}`, // Bearer token for Authorization
    //       // 'auth-token': tokenJWT,              // auth-token in the header
    //       'Content-Type': 'application/json',
    //     },
    //   }
    // );
   
    //   const answer = response.data;
    //   //console.log('answer is: '+JSON.stringify(answer));
    //   const parsedRes = JSON.parse(answer.body);
    //   setConversationId(parsedRes.conversation_id);
    //   //console.log('The ID is: ' + parsedRes.conversation_id);
    //   setError(null); // Clear any previous errors
    //   return answer;
   
    // } catch (error) {
    //   console.error("Error:", error);
    //   setError("Failed to fetch the response. Please try again.");
    //   throw error; // Re-throw the error if needed elsewhere
    // }

    try {
      // Make the API request with a 20-second timeout
      const response = await axios.post(apiUrl, postData, {
        headers: {
          Authorization: `Bearer ${tokenJWT}`, // Bearer token for Authorization
          'Content-Type': 'application/json',
        },
        timeout: 60000, // Set timeout to 45 seconds (45000 milliseconds)
      });
    
      const answer = response.data;
      //console.log('answer is: '+JSON.stringify(answer));
      const parsedRes = JSON.parse(answer.body);
      setConversationId(parsedRes.conversation_id);
      setError(null); // Clear any previous errors
      return answer;
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        console.error('Request timed out:', error);
        setError('The request took too long to complete. Please try again.');
        setResponseError('The request took too long to complete. Please try again.')
      } else {
        console.error('An error occurred:', error);
        setResponseError('Request is missing key information needed to process accurate results. Please clarify or rephrase and try again.');
        setError(error.message || 'An unexpected error occurred.');
      }
    }
  };


  const handleSend = async () => {
    const userPrompt = inputQuestion || value; // Use the context value if inputQuestion is empty
    setValue(""); // Reset the context value after using it
    setIsLoading(true);
    setLocalTranscript(userPrompt);
    logEvent({ action: 'UserInput', message: userPrompt, type: 'input' });
    
    try {
      //console.log('this si user prompt: '+userPrompt);
      await updateUserActivity(userPrompt);
      const answer = await generateAnswer(userPrompt);
      const newConversation = {
        id: new Date().getTime(),
        date: new Date(),
        question: userPrompt,
        response: answer,
      };
      setConversation([newConversation, ...conversation]);
      setInputQuestion("");
      logEvent({ action: 'AIResponse', message: 'AI response generated', type: 'response' });
    } catch (error) {
      console.error("Error:", error);
      
      logEvent({ action: 'Error', message: 'Failed to generate AI response', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default action of the Enter key (e.g., form submission)
      if (!isLoading) {
        handleSend();
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading && conversation.length === 0) {
      navigate('/sparkles');
    } else if (conversation.length > 0) {
      navigate('/targetPage');
    }
  }, [isLoading, conversation, navigate]);

  const handleClear = () => {
    setInputQuestion("");
  };

  useEffect(() => {
    if (selectedQuery) {
      setInputQuestion(selectedQuery);
    }
  }, [selectedQuery]);

  useEffect(() => {
    if (value) {
      setInputQuestion(value);
    }
  }, [value]);

  useEffect(() => {
    if (selectedManagedQuery) {
      setInputQuestion(selectedManagedQuery);
    }
  }, [selectedManagedQuery]);

  const handleDropdownItemClick = (item) => {
    setInputQuestion(item);
    setShowDropdown(false);
    inputRef.current.focus();
  };

  const handleRemoveItem = (item) => {
    // Implement remove logic here
    // console.log('Removing:', item);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const promptBarBackgroundColor = inputQuestion ? 'bg-white' : 'bg-zinc-100';

  

  const buttonClass = `
    w-full min-w-[120px] h-[40px] min-h-[40px] rounded-2xl 
    bg-[#f1b20b] text-center p-2 text-[#ffffff] font-bold mb-2
    whitespace-nowrap overflow-hidden text-ellipsis
  `;

  const LogoSVG = ({ isActive }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11.7891 21.8977C17.3119 21.8977 21.7891 17.4206 21.7891 11.8977C21.7891 6.37486 17.3119 1.89771 11.7891 1.89771C6.26621 1.89771 1.78906 6.37486 1.78906 11.8977C1.78906 17.4206 6.26621 21.8977 11.7891 21.8977Z" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.7891 15.8977C13.9982 15.8977 15.7891 14.1068 15.7891 11.8977C15.7891 9.68857 13.9982 7.89771 11.7891 7.89771C9.57992 7.89771 7.78906 9.68857 7.78906 11.8977C7.78906 14.1068 9.57992 15.8977 11.7891 15.8977Z" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.71875 4.82764L8.95875 9.06764" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.6191 14.7278L18.8591 18.9678" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.6191 9.0676L18.1491 5.5376" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.71875 18.9678L8.95875 14.7278" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  
  return (
    <div className="input-container w-full max-w-[960px] mx-auto px-4 sm:px-6 lg:px-8">  
      <div className="relative">
        {showDropdown && (
          <div 
            ref={dropdownRef} 
            className={`absolute bottom-full left-0 right-0 flex flex-col items-start p-1.5 gap-1.5 rounded-[14px] border 
            border-[#F2F2F2] bg-white shadow-lg mb-2 max-h-[270px] overflow-y-auto ${isMobile ? 'w-full' : 'w-[640px]'}`}
          >
            {managedQueries.map((item) => (
              <DropdownItem 
                key={item.id} 
                text={item.query_name} 
                onClick={() => handleQueryClick(item.query_name)}
              />
            ))}
          </div>
        )}
        {/* <div className={`rounded-full border border-gray-300 flex items-center p-4 focus-within:border-2 focus-within:border-black transition-all duration-200 ${promptBarBackgroundColor}`}> */}
        <div className={`rounded-full border border-gray-300 flex items-center p-4 justify-between flex-1 self-stretch 
        bg-[var(--Foundation-Primary-black-Light,#F2F2F2)] 
        border-[var(--foundation-primary-black-light-hover,#D9D9D9)] 
        focus-within:border focus-within:border-black transition-all 
        duration-200 ${promptBarBackgroundColor}`}>
          <div 
            className={`flex-shrink-0 flex items-center justify-center p-2 rounded-2xl ${isInputFocused ? 'bg-black' : 'bg-zinc-300'} h-[34px] w-[34px] transition-colors duration-200 cursor-pointer`}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {isInputFocused ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M11.7891 21.8977C17.3119 21.8977 21.7891 17.4206 21.7891 11.8977C21.7891 6.37486 17.3119 1.89771 11.7891 1.89771C6.26621 1.89771 1.78906 6.37486 1.78906 11.8977C1.78906 17.4206 6.26621 21.8977 11.7891 21.8977Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.7891 15.8977C13.9982 15.8977 15.7891 14.1068 15.7891 11.8977C15.7891 9.68857 13.9982 7.89771 11.7891 7.89771C9.57992 7.89771 7.78906 9.68857 7.78906 11.8977C7.78906 14.1068 9.57992 15.8977 11.7891 15.8977Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.71875 4.82764L8.95875 9.06764" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.6191 14.7278L18.8591 18.9678" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.6191 9.0676L18.1491 5.5376" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.71875 18.9678L8.95875 14.7278" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            ) : (
              <img
                loading="lazy"
                src='https://cdn.builder.io/api/v1/image/assets/TEMP/85a808bea0eabc96e3d06b98d17479b4c423c8f09590ff7a6a71c64ebc5ab8fd?placeholderIfAbsent=true&apiKey=0285d82b49fb4c219f9a2d1162bd0be4'
                alt=""
                className="w-5 h-5 object-contain"
              />
            )}
          </div>
          <input
            type="text"
            value={inputQuestion}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            className={`flex-grow min-w-0 px-2 sm:px-4 py-2 text-sm outline-none ${promptBarBackgroundColor}`}
            placeholder={`Hi ${firstName}! What can I help you with today?`} 
            ref={inputRef}
          />
          {isLoading ? (
            <div className="flex-shrink-0 p-2">
              <FontAwesomeIcon 
                icon={faSpinner} 
                className="w-6 h-6 text-black-400" 
                spin 
              />
            </div>
          ) : (
            <button 
              className={`flex-shrink-0 p-2 rounded-full transition-colors duration-200 ${isLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
              onClick={handleSend}
              disabled={isLoading}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <g clipPath="url(#clip0_335_1857)">
                  <path d="M22.1422 12.9584H6.58584" stroke={isHovered ? "black" : "#D9D9D9"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M22.1421 12.9583L3.05025 22.1507L6.58579 12.9583L3.05025 3.76591L22.1421 12.9583Z" stroke={isHovered ? "black" : "#D9D9D9"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_335_1857">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.958252)"/>
                  </clipPath>
                </defs>
              </svg>
            </button>
          )}
        </div>
      </div>
      {error && (
        <div className="error-message text-red-500 mt-2">
          {error}
          <button
            onClick={handleSend}
            className="ml-4 text-blue-500 font-inter underline"
          >
            Retry
          </button>
        </div>
      )}
    </div>
  );
};

export default PromptBar;