import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmSignIn } from '@aws-amplify/auth';
import { Lock, Eye, EyeOff } from 'lucide-react';

// Custom Alert component
const Alert = ({ children, type = 'success' }) => (
  <div className={`w-full p-4 rounded-[13px] ${type === 'success' ? 'bg-[#ECF9F7] border-[#34D399]' : 'bg-[#FEF2F2] border-[#F87171]'} border flex items-start space-x-2 mb-4`}>
    <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${type === 'success' ? 'text-[#34D399]' : 'text-[#F87171]'} flex-shrink-0`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      {type === 'success' ? (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      ) : (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      )}
    </svg>
    <div className={`${type === 'success' ? 'text-[#065F46]' : 'text-[#991B1B]'} text-sm`}>{children}</div>
  </div>
);

const ChangePassword = ({ setIsAuthenticated }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [stage, setStage] = useState('request');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const newUser = location.state?.newUser;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await confirmSignIn({ challengeResponse: newPassword });
      setShowSuccessAlert(true);
      setIsAuthenticated(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('Error changing password:', error);
      setError(error.message || 'An error occurred while changing the password');
    }
  };
  return (
    // <div className="flex h-screen overflow-hidden">
    <div className="flex flex-col md:flex-row w-full">
      {/* Left side - Change Password form */}
      {/* <div className="w-1/2 bg-gray-100 flex items-center justify-center">
        <div className="flex flex-col items-center w-[364px]"> */}
        <div className="w-full md:w-1/2 bg-gray-100 flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center w-full max-w-[364px] px-4">
          <div className="self-stretch text-center mb-8">
            <h1 className="text-[61px] font-inter font-black leading-[120%] mb-2 text-black" style={{ fontFamily: 'Inter, sans-serif' }}>
              Change Password!
            </h1>
            <p className="text-[16px] font-inter font-normal leading-[120%] text-black mt-2" style={{ fontFamily: 'Inter, sans-serif' }}>
            Logging in for the first time? 
            Please update your password.
            </p>
          </div>
          
          {showSuccessAlert && (
            <Alert>
              Password updated! Your password is successfully updated. Please login again with new password.
            </Alert>
          )}
          
          {error && <Alert type="error">{error}</Alert>}
          <form onSubmit={handleSubmit} className="w-full space-y-4">
              <>
                <div className="flex items-center gap-[14px] p-[14px] self-stretch rounded-[14px] bg-white">
                  <Lock size={24} />
                  <input
                    type={showPassword ? "password" : "text"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full text-[16px] font-normal leading-[120%] focus:outline-none"
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      color: newPassword ? 'black' : '#D9D9D9'
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="focus:outline-none"
                  >
                    {showPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                  </button>
                </div>
                <div className="flex items-center gap-[14px] p-[14px] self-stretch rounded-[14px] bg-white">
                  <Lock size={24} />
                  <input
                    type={showConfirmPassword ? "password" : "text"}
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="w-full text-[16px] font-normal leading-[120%] focus:outline-none"
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      color: confirmPassword ? 'black' : '#D9D9D9'
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="focus:outline-none"
                  >
                    {showConfirmPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                  </button>
                </div>
              </>
            <button
              type="submit"
              className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-[#EBAE12] text-white font-bold text-[16px] hover:bg-[#D49D10] transition duration-300 relative"
              style={{ fontFamily: 'Inter, sans-serif' }}
            >
              Change Password
            </button>
          </form>

          <br />
          
          <button
            onClick={() => navigate('/signin')}
            className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-white text-[#EBAE12] font-bold text-[16px] hover:bg-[#FDF7E7] transition duration-300 border border-[#EBAE12]"
            style={{ fontFamily: 'Inter, sans-serif' }}
          >
            Login
          </button>
        </div>
      </div>


      <div className="hidden md:flex md:w-1/2 bg-white justify-center items-center relative overflow-hidden">
        {/* Logo container */}
        <div className="flex w-[23rem] flex-col items-start gap-5 absolute left-[6.5rem] top-[5rem]">
          <img
            src="Frame8.png"
            alt="FINDRAISER Logo"
            className="w-full h-auto object-contain"
          />
        </div>

        {/* Branding image container */}
        <div className="absolute bottom-0 right-0 w-[250px] h-[250px]">
          <img
            src="Branding.png"
            alt="FINDRAISER Branding"
            className="w-full h-full object-contain md:scale-90 sm:scale-75 lg:scale-100"
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;