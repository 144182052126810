// src/hooks/useLambda.js

import { fetchAuthSession } from 'aws-amplify/auth';
//import {Auth} from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk';
import {jwtDecode} from 'jwt-decode';
import { awsExports } from '../../aws-exports';

// AWS.config.update({
//   region: awsExports.Auth.Cognito.region,
// });
AWS.config.update({
    region: awsExports.Auth.Cognito.region,
  });

const useLambda = () => {
  
  const getJWT = async () => {
    const {tokens} = await fetchAuthSession();
    //console.log('token is: '+tokens.toString());
    //console.log('referesh token is : '+tokens.refreshToken.toString());
    if (!tokens || !tokens.idToken) {
      throw new Error('No authenticated user found');
    }
    const jwtToken = tokens.idToken.toString();
    return jwtToken;
  }

  // const configureLambda = async (jwtToken) => {
    
  //   AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //     IdentityPoolId: awsExports.Auth.Cognito.identityPoolId,
  //     Logins: {
  //       [`cognito-idp.${awsExports.Auth.Cognito.region}.amazonaws.com/${awsExports.Auth.Cognito.userPoolId}`]: jwtToken,
  //     },
  //   });

  //   await AWS.config.credentials.getPromise();
  //   //return jwtToken;
  // };

  const configureLambda = async (jwtToken) => {
    if (!jwtToken) {
      throw new Error('JWT token is missing or invalid');
    }
    try {
      //console.log('jwt token in configure lambda is: '+jwtToken);
      AWS.config.region = awsExports.Auth.Cognito.region;
  
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsExports.Auth.Cognito.identityPoolId,
        Logins: {
          [`cognito-idp.${awsExports.Auth.Cognito.region}.amazonaws.com/${awsExports.Auth.Cognito.userPoolId}`]: jwtToken,
        },
      });
  
      // Clear cached credentials
      AWS.config.credentials.clearCachedId();
  
      // Attempt to get credentials
      await AWS.config.credentials.getPromise();
  
      //console.log("Credentials successfully obtained");
    } catch (error) {
      console.error("Error configuring Lambda credentials:", error);
      throw error;
    }
  };

  const decodeToken = (token) => {
    const decoded = jwtDecode(token);
    return decoded;
  };


  return { configureLambda,decodeToken, getJWT };
};

export default useLambda;
