import React, { useContext, useEffect } from 'react'
import Sidebar from './sidebar/Sidebar'
import Sidebar_v2 from './sidebar/Sidebar_v2';
// import { Home } from '@mui/icons-material'
import { MyContext } from '../Context/MyContext';
import { useNavigate } from 'react-router-dom';
import Home from "./Home/Home";
import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth';
import TargetPage from './Home/TargetPage';
import {
  jwtTokenState, 
  rolesState,
  subState,
  tenantGroupState,
  userEmailState,
  isSuperAdminState,
  isTenantAdminRoleState,
  expState,
  firstNameState,
  displayRoleState
} from '../store/atoms';
import { atom, useRecoilState } from 'recoil';
import useLambda from './hooks/useLambda';
import { useMediaQuery } from '@mui/material';

const Main = () => {
    //const { isDarkTheme } = useContext(MyContext);
    const navigate = useNavigate();
    const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
    const [roles, setRoles] = useRecoilState(rolesState);
    const [sub, setSub] = useRecoilState(subState);
    const [tenantGroup, setTenantGroup] = useRecoilState(tenantGroupState);
    const [userEmail, setUserEmail] = useRecoilState(userEmailState);
    const [isSuperAdmin, setisSuperAdmin] = useRecoilState(isSuperAdminState);
    const [isTenantAdminRole, setisTenantAdminRole] = useRecoilState(isTenantAdminRoleState);
    const [exp, setExp] = useRecoilState(expState);
    const [firstName, setFirstName] = useRecoilState(firstNameState);
    const [displayRole, setDisplayRole] = useRecoilState(displayRoleState);
    const { configureLambda, decodeToken, getJWT } = useLambda();
    const isMobile = useMediaQuery('(max-width:768px)');


    useEffect(() => {
      async function checkAuth() {
        try {
          await fetchAuthSession();
        } catch (error) {
          console.error("Not authenticated, redirecting to signin");
          navigate('/signin')
        }
    }
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const session = await fetchAuthSession();
        if (!session) {
          throw new Error("No authenticated session");
        }
        const jwtToken = await getJWT();
        //console.log('jwt is: '+jwtToken);
        setJwtToken(jwtToken);
        const decodedToken = decodeToken(jwtToken);
        const cognitoRoles = Array.isArray(decodedToken['cognito:roles']) ? decodedToken['cognito:roles'] : [];
        if(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole')) {
          setDisplayRole('Global Admin');
        } else if(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole')) {
          setDisplayRole('Administrator');
        }
        const groups = decodedToken['cognito:groups'];
        const expTime = decodedToken['exp'];
        //console.log('tenant groups: '+groups);
        let filteredGroups = [];
        if (groups.includes("Tenants")) {
          filteredGroups = groups.filter(group => group !== 'Tenants');
        } else if (groups) {
          filteredGroups = [...groups];
        }
        setTenantGroup(filteredGroups);
        setRoles(cognitoRoles);
        setisSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
        setisTenantAdminRole(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
        setExp(expTime);

      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };

    fetchRoles();

    const fetchUserName = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        setFirstName(userAttributes.given_name);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };
  
    fetchUserName();
    //console.log('data fetched and stored');
  }, []);

  
    const themeStyles = {
        // backgroundColor: isDarkTheme ? '#333' : '#FFF',
        // color: isDarkTheme ? '#FFF' : '#000',
        backgroundColor: '#FFF',
        color: '#000',
        minHeight: '100vh',
        display: "flex",
        width: "100%"
      };
  return (
    <div  className={`flex ${isMobile ? '' : 'h-screen'}`}>
    {/* Sidebar_v2 */}
    <div className="flex-shrink-0">
      {/* <Sidebar_v2 /> */}
    </div>
        {/* <Home /> */}
        {<TargetPage/>}
    </div>
  )
}

export default Main

// import React, { useContext } from 'react';
// import { MyContext } from '../Context/MyContext';
// import CurrentConversation from './Home/CurrentConversation';

// const Main = () => {
//   const { isDarkTheme } = useContext(MyContext);
//   const themeStyles = {
//     backgroundColor: isDarkTheme ? '#333' : '#FFF',
//     color: isDarkTheme ? '#FFF' : '#000',
//   };

//   return (
//     <div style={themeStyles} className="container">
//       <header className="header">Chat Application</header>
//       <CurrentConversation />
//     </div>
//   );
// };

// export default Main;
