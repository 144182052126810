import React, { useState, useEffect, useRef } from "react";
import AWS from 'aws-sdk';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { conversationState  } from "../../store/atoms";
import { Fragment } from "react";
import ConversationCard from "./ConversationCard";
import { saveAs } from "file-saver";
import { utils, write } from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faFileExport, faSpinner, faFileImport, faThumbsDown, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { SiMicrosoftexcel } from 'react-icons/si';
import { jsPDF } from "jspdf";
import { Document, Packer, Paragraph, TextRun, Table, TableRow, TableCell, BorderStyle, HeadingLevel, WidthType } from "docx";
import { logEvent } from '../../cloudwatchLogger';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useMediaQuery } from '@mui/material';
import '../../styles.css'



const LogoAndText = () => {
  return (
    <div style={{
      display: 'flex',
      padding: '50px 10px',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '5px',
      flex: '1 0 0',
      alignSelf: 'stretch',
    }}>
      <img 
        src="Logoicon.png" 
        alt="Logo and Text" 
        style={{
          width: '35px',
        }}
      />
    </div>
  );
};

const CurrentConversation = ({toggleSidebar }) => {
  const [editingId, setEditingId] = useState(null);
  const [editedText, setEditedText] = useState("");
  const conversation = useRecoilValue(conversationState);
  const setConversation = useSetRecoilState(conversationState);
  //console.log('conversation in current conversation: '+conversation.length);
  const [isRegenerating, setIsRegenerating] = useState(null);
  const [isRegeneratingThumbsDown, setIsRegeneratingThumbsDown] = useState(null);
  const [isExporting, setIsExporting] = useState(null);
  const [notification, setNotification] = useState("");
  const conversationContainerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  
 // const setLogEvents = useSetRecoilState(logEventsState);

  // AWS S3 Configuration
  const s3 = new AWS.S3({
    accessKeyId: 'AKIA2EJNKTWOV7X2F4VC',
    secretAccessKey: '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',
    region: 'us-east-1',
  });
 
  const scrollToBottom = () => {
    if (conversationContainerRef.current) {
      conversationContainerRef.current.scrollTop = conversationContainerRef.current.scrollHeight;
    }
  };
 
  useEffect(() => {
    scrollToBottom();
  }, [conversation]);
  
  // const showNotification = (message) => {
  //   setNotification(message);
  //   setTimeout(() => {
  //     setNotification("");
  //   }, 5000);
  // };
  
// const logEvent = (event) => {
//     setLogEvents((prevEvents) => [...prevEvents, event]);
//   };

  const handleThumbsDown = async (id) => {
    setIsRegeneratingThumbsDown(id);
    
    try {
      const originalPrompt = conversation.find(chat => chat.id === id).question;
      
      const response = await fetch("https://nhbli32qjd.execute-api.us-east-1.amazonaws.com/c-valenta/c-valenta", {
        method: 'POST',
        body: JSON.stringify({ user_prompt: originalPrompt }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to regenerate response');
      }
  
      const data = await response.json();
  
      const updatedConversation = conversation.map(chat => {
        if (chat.id === id) {
          return { 
            ...chat, 
            response: data, 
            date: new Date().toISOString()  // Update the timestamp
          };
        }
        return chat;
      });
      setConversation(updatedConversation);
      
      logEvent({ eventType: 'thumbsDownRegenerate', details: `Response regenerated for question ID: ${id}` });
      
      //showNotification("Response regenerated successfully!");
    } catch (error) {
      console.error('Failed to regenerate response:', error);
      //showNotification(`Failed to regenerate: ${error.message}`);
    } finally {
      setIsRegeneratingThumbsDown(null);
    }
  };
  

  

  const getFormattedDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  const handleEdit = (id) => {
    const chatToEdit = conversation.find(chat => chat.id === id);
    setEditingId(id);
    setEditedText(chatToEdit.question);
  };

  const handleSaveEdit = async (id, newText) => {
    const originalChat = conversation.find(chat => chat.id === id);

    if (originalChat.question === newText) {
      //showNotification("No changes were made. Nothing to save.");
      setEditingId(null);
      return;
    }

    setIsLoading(true);

    try {
      const updatedConversation = conversation.map(chat =>
        chat.id === id
          ? { ...chat, question: newText, date: new Date().toISOString() }
          : chat
      );
      setConversation(updatedConversation); 

        const response = await fetch("https://kjizki5vga.execute-api.us-east-1.amazonaws.com/text2sql/text2sql", {
        method: 'POST',
        body: JSON.stringify({ user_prompt: newText }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to regenerate response');
      }

      const data = await response.json();

      const updatedConversationWithResponse = updatedConversation.map(chat =>
        chat.id === id
          ? { ...chat, response: data, date: new Date().toISOString() }
          : chat
      );
      setConversation(updatedConversationWithResponse);

      // showNotification("Response regenerated successfully!");
      logEvent({ eventType: 'editAndRegenerate', details: `Response regenerated for question ID: ${id}` });
    } catch (error) {
      console.error('Failed to regenerate response:', error);
      // showNotification(`Failed to regenerate: ${error.message}`);
    } finally {
      setIsLoading(false);
      setEditingId(null);
    }
  };

 
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; s.length > i; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
 
  const sanitizeFileName = (name) => {
    return name.replace(/[<>:"\/\\|?*]/g, "_");
  };
  //console.log('conversations is : '+JSON.stringify(conversation));
// h-[325px]
  const scrollbarClasses = `
  
  overflow-auto 
  
  /* Hide scrollbar by default */
  [&::-webkit-scrollbar]:w-0
  hover:[&::-webkit-scrollbar]:w-2
  
  /* Smooth transition */
  [&::-webkit-scrollbar]:transition-[width]
  [&::-webkit-scrollbar]:duration-500
  [&::-webkit-scrollbar]:ease-in-out
  
  /* Thumb and track styles */
  [&::-webkit-scrollbar-thumb]:bg-white
  [&::-webkit-scrollbar-thumb]:rounded-full 
  [&::-webkit-scrollbar-track]:bg-gray-100
  
  /* Hide scrollbar buttons/arrows */
  [&::-webkit-scrollbar-button]:hidden
  [&::-webkit-scrollbar-button]:h-0
  [&::-webkit-scrollbar-button]:w-0
  
  /* Firefox support */
  scrollbar-none
  hover:scrollbar-thin 
  scrollbar-thumb-gray-400 
  scrollbar-track-gray-100
`;
 
  return (
    <>
    {!isMobile &&
            <div className="pt-10 mt-0"> {/* Using pt-10 for 40px padding-top */}
            <div className="flex items-center gap-5 w-[203px] mx-auto">
              <div className="w-[60px] h-[60px] flex-shrink-0 relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                  <circle cx="30" cy="30" r="30" fill="#EBAE12"/>
                </svg>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="28.749" 
                  height="27.454" 
                  viewBox="0 0 32 30" 
                  fill="none"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  <circle cx="13.8434" cy="13.4684" r="12.2184" stroke="white" strokeWidth="2.5"/>
                  <path d="M23.043 21.3733L30.374 28.7043" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
                </svg>
              </div>
            </div>
          </div>
    }

    {/* <div ref={conversationContainerRef} className="h-[65vh] overflow-auto flex flex-col-reverse scrollbar   
                scrollbar-thumb-gray-400 scrollbar-track-gray-200 
                hover:scrollbar-thumb-gray-500
                scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-hide-buttons"> */}
    <div ref={conversationContainerRef} className={`${scrollbarClasses}
    ${isMobile ? 'p-5' : 'h-[70vh] px-10'} overflow-auto flex flex-col-reverse`}>
      <div className="w-full flex flex-col items-center gap-4">
        {conversation.slice().reverse().map((chat, index) => (
          <Fragment key={chat.id}>
            <ConversationCard
              chat={chat}
              isResponse={false}
              isEditing={editingId === chat.id}
              editedText={editedText}
              onEdit={() => handleEdit(chat.id)}
              onChange={(e) => setEditedText(e.target.value)}
              onSave={() => handleSaveEdit(chat.id, editedText)}
              isLoading={isLoading}
            />
            <ConversationCard
              chat={chat}
              isResponse={true}
              onSave={(newText) => handleSaveEdit(chat.id, newText)}
            />
          </Fragment>
        ))}
      </div>
    </div>
   
    </>
  );
};

export default CurrentConversation;

 {/* {isRegeneratingThumbsDown === chat.id ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      style={{ marginRight: '10px' }}
                      size="lg"
                      spin
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      style={{ cursor: 'pointer', marginRight: '10px', fontSize: '1.5rem', color: '#f1b20b' }}
                      title="Regenerate Response"
                      onClick={() => handleThumbsDown(chat.id)}
                    />
                  )} */}

