import React, { useState, useEffect, useRef, useCallback  } from 'react';
import { useRecoilState, useSetRecoilState  } from 'recoil';
import { conversationState, pinnedQueriesState, responseErrorState   } from '../../store/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faSpinner, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { logEvent } from '../../cloudwatchLogger';
import { faHeart, faRedo, faFileExcel, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { SiMicrosoftexcel } from 'react-icons/si';
import { Pin, Loader, Check } from 'lucide-react';
import { jsPDF } from "jspdf";
import { FileQuestion, X} from 'lucide-react';
import { Document, Packer, Paragraph, TextRun, Table, TableRow, TableCell, BorderStyle, HeadingLevel, WidthType } from "docx";
import { useRecoilValue } from "recoil";
import { saveAs } from "file-saver";
import { utils, write } from 'xlsx';
import { Fragment } from "react";
import { importNotificationsState } from '../../store/atoms';
import useLambda from '../hooks/useLambda';
import AWS from 'aws-sdk';
import { awsExports } from '../../aws-exports';
import { useMediaQuery } from '@mui/material';
import { Maximize2 } from 'lucide-react';
import '../../styles.css';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import {
  jwtTokenState, 
  subState,
  isSuperAdminState,
  isTenantAdminRoleState
} from '../../store/atoms';


// Lambda invoker utility
const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  //console.log('action is : '+action);
  const params = {
    FunctionName: lambdaFunctionName,
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    // console.log('knowledge base is: '+JSON.stringify(result));
    //console.log('result from the lambda is: '+JSON.stringify(result.data));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};

const extractFirstKeys = (table) => {
  return table.map((item, index) => {
    const firstKey = Object.keys(item)[0];
    const firstVal = item[firstKey];
    return (
      <li key={index}>
        {index + 1}. {firstVal}
      </li>
    );
  });
};

const formatTime = (time) => {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  return `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;
};

// Modal component
const Modal = ({ onClose, children }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl w-96 max-w-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-inter font-bold text-gray-800">unpin a query to add a new one</h2>
        <button 
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X size={24} />
        </button>
      </div>
      <div className="modal-content mb-4">{children}</div>
    </div>
  </div>
);

const ModalContent = ({ pinnedQueries, queryText, setPinnedQueries, setModalOpen, deleteQuery, addQuery }) => {
  const handleUnpinAndPin = async (oldQueryId) => {
    try {
      // Unpin the old query (delete it)
      await deleteQuery(oldQueryId);
      //console.log('delete query with id: '+oldQueryId)
      // Update the pinnedQueries state by removing the query with the oldQueryId
      setPinnedQueries((prevQueries) =>
        prevQueries.filter((query) => query.id !== oldQueryId)
      );
      //console.log('pinned queries are: '+pinnedQueries);

      const data = await addQuery(queryText);
      //console.log("this is my querytext3" +queryText);
      const newQuery = {
          icon: <FileQuestion size={24} className="text-yellow-600" />,
          text: data[0].query_name,
          isPinned: true,
          id: data[0].id
        };
        const updatedQueries = [...pinnedQueries, newQuery];
        setPinnedQueries(updatedQueries);

      // // Add the new query to the pinnedQueries state
      // setPinnedQueries((prevQueries) => [...prevQueries, newQuery]);

      // Close the modal after pinning the new query
      setModalOpen(false);
    } catch (error) {
      console.error("Error unpinning and pinning query:", error);
    }
  };

  return (
    <div className="space-y-3">
      {pinnedQueries.map((pinnedQuery) => (
        <div key={pinnedQuery.id} className="flex justify-between items-center p-2 bg-gray-100 rounded-lg">
          <span className="text-gray-800 font-inter">{pinnedQuery.text}</span>
          <button
            className="bg-yellow-400 text-white font-inter px-3 py-1 rounded-full hover:bg-yellow-500 transition-colors"
            onClick={() => handleUnpinAndPin(pinnedQuery.id)}
          >
            Unpin & Pin
          </button>
        </div>
      ))}
    </div>
  );
};

const PinButton = ({ handlePinToggle, pinnedQueries, queryText }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    // console.log("handleClick called");
    setIsLoading(true);
    try {
      await handlePinToggle();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error pinning query:", error);
    }
  };
  return (
    <button
      onClick={handleClick}
      className="mt-4 ml-2 text-black-500 relative"
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader className="animate-spin" size={16} />
      ) : (
        <Pin size={16} fill="none" />
      )}
    </button>
  );
};

const ConversationCard = ({ 
  chat, 
  isResponse, 
  isEditing, 
  editedText, 
  onEdit, 
  onChange, 
  onSave, 
  onExport,
  //isLoading 
}) => {
  const [response, setResponse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isExporting, setIsExporting] = useState(null);
  const [notification, setNotification] = useState("");
  const conversation = useRecoilValue(conversationState);
  const setConversation = useSetRecoilState(conversationState)
  //const [logEvents] = useRecoilState(logEventsState);
  const [pinnedQueries, setPinnedQueries] = useRecoilState(pinnedQueriesState);
  const [responseError, setResponseError] = useRecoilState(responseErrorState);
  //const setLogEvents = useSetRecoilState(logEventsState);
  //const [isPinned, setIsPinned] = useState(false);
  const queryText = chat.question;
  const isPinned = pinnedQueries.some((pinnedQuery) => pinnedQuery.text === queryText);
  const [isModalOpen, setModalOpen] = useState(false);
  const setImportNotifications = useSetRecoilState(importNotificationsState);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [lambdaFunctionName] = useState(awsExports.Lambda.queryManagement);
  const [isTablePopup, setIsTablePopup] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [tenantGroup, setTenantGroup] = useState([]);
  const { decodeToken, getJWT } = useLambda();

  const [jwtToken,setJwtToken] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState('');
  const [isTenantAdmin, setIsTenantAdmin] = useState('');
  const [sub,setSub] = useState('');
  const tableRef = useRef(null);

  const isMobile = useMediaQuery('(max-width:768px)');
  const [showFullText, setShowFullText] = useState({});
  // const jwtToken = useRecoilValue(jwtTokenState);
  // const isSuperAdmin = useRecoilValue(isSuperAdminState);
  // const isTenantAdmin = useRecoilValue(isTenantAdminRoleState);
  // const sub = useRecoilValue(subState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  



  const updateNotifications = (success, message, timestamp) => {
  setImportNotifications((prevNotifications) => [
    ...prevNotifications,
    { success, message, timestamp: new Date().getTime() },
  ]);
};


  const handlePinToggle = async () => {
     if (pinnedQueries.length < 6) {
      // Add a new pinned query
      try {
        //console.log("inside else if the size is less than 6", queryText);
        const data = await addQuery(queryText);  // Call addQuery to add to DB
        //console.log('data inside handle pin toggle is : '+JSON.stringify(data));
        const newQuery = {
          icon: <FileQuestion size={24} className="text-yellow-600" />,
          text: data[0].query_name,
          isPinned: true,
          id: data[0].id
        };
        const updatedQueries = [...pinnedQueries, newQuery]; // Update state
        setPinnedQueries(updatedQueries);
        showNotification("Query pinned successfully!");
      } catch (error) {
        console.error("Error pinning query:", error);
        showNotification("Failed to pin query. Please try again.");
      }
    } else {
      //console.log('if there are already 6 queries', queryText)
      // If there are already 6 pinned queries, open the modal to unpin
      setModalOpen(true);
    }
  };
  
  useEffect(() => {
    //console.log('Pinned Queries updated:', pinnedQueries);
  }, [pinnedQueries]);

  const addQuery = async (query) => {
    const lambda = new AWS.Lambda();
    const payload = {
      'created_by' :  sub,
      'pinned_query': query
      }
    // Invoke Lambda function to add the query to the database
    const data = await invokeLambda('addPrompts', payload, lambda, lambdaFunctionName);
    
    //console.log('Response from addPrompt Lambda:', JSON.stringify(data));
    return data;
    // If you want to update availableQueries based on the Lambda response
    // setAvailableQueries(data);
    
    setTimeout(() => {
      logEvent({ eventType: 'queryPinned', details: `Pinned query: ${query.id}` });
    }, 0);
  };

  const deleteQuery = async (id) => {
    //console.log(`Deleting query with id: ${id}`);
  
    const updatedDeleteQueries = pinnedQueries.filter((query) => query.id !== id);
    setPinnedQueries(updatedDeleteQueries);

    const lambda = new AWS.Lambda();
    const deleteData = await invokeLambda('deletePrompts',{ 'id':id }, lambda, lambdaFunctionName);
    setTimeout(() => {
      logEvent({ eventType: 'queryUnpinned', details: `Unpinned query with id: ${id}` });
    }, 0);
  };

  useEffect(() => {
    if (isResponse && chat.response?.body) {
      try {
        const parsedResponse = JSON.parse(chat.response.body);
        //console.log(`parsed response is : ${JSON.stringify(parsedResponse)}` );
        setResponse(parsedResponse);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        setResponse({ summary: chat.response.body, table: [] });
      }
    }
  }, [chat.response?.body, isResponse]);

  const formatTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
      tableRef.current.scrollLeft = 0;
    }
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const disablePreviousButton = currentPage === 1;
  const disableNextButton = currentPage * rowsPerPage >= response?.table?.length;

  const exportToExcel = (id, question) => {
    logEvent({ action: 'UserExportedResponse', details: 'Export to Excel was performed by the user', type: 'excel' });
    setIsExporting(id);
  
    setTimeout(() => {
      try {
        const chat = conversation.find(chat => chat.id === id);
        if (!chat || !chat.response || !chat.response.body) {
          console.error('No response available to export.');
          showNotification('Failed to export: No response available to export in excel format.');
          return;
        }
  
        const responseText = chat.response.body;
        const jsonResponse = JSON.parse(responseText);
  
        if (!jsonResponse.table) {
          throw new Error('No Data to export from the prompt');
        }
  
        const keys = [...new Set(jsonResponse.table.flatMap(Object.keys))];
        const data = jsonResponse.table.map(donor => {
          let row = {};
          keys.forEach(key => {
            row[key] = donor[key] || '';
          });
          return row;
        });
  
        const description = jsonResponse.description || '';
        
        // Create the Excel workbook and worksheet
        const wb = utils.book_new();
        const ws = utils.json_to_sheet(data, { header: keys });
  
        // Add the description as a comment to the first cell
        if (description) {
          const cellRef = utils.encode_cell({r: 0, c: 0});
          if (!ws[cellRef]) ws[cellRef] = {};
          ws[cellRef].c = [{ a: "Author", t: description }];
        }
  
        utils.book_append_sheet(wb, ws, "Donors");
        const wbout = write(wb, { bookType: "xlsx", type: "binary" });
        const fileName = `${sanitizeFileName(question)}-Donors.xlsx`;
        const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
        saveAs(blob, fileName);
        showNotification("Export successful!");
        updateNotifications(true, "Excel export successful", new Date().getTime());
      } catch (error) {
        console.error('Failed to export to Excel:', error);
        showNotification(`Failed to export: ${error.message}`);
        updateNotifications(false, "CSV export failed: " + error.message, new Date().getTime());
      } finally {
        setIsExporting(null);
      }
    }, 4000);
  };

  const exportToPDF = (id, question) => {
    logEvent({ action: 'UserExportedResponse', details: 'Export to PDF was performed by the user', type: 'pdf' });
    setIsExporting(id);
    
    setTimeout(() => {
      try {
        const chat = conversation.find(chat => chat.id === id);
        if (!chat || !chat.response || !chat.response.body) {
          showNotification('No response available to export pdf.');
          return;
        }
  
        const doc = new jsPDF();
        const responseText = chat.response.body;
        const jsonResponse = JSON.parse(responseText);
  
        // Set font size and line height
        doc.setFontSize(12);
        const lineHeight = 7;
  
        let yPosition = 20;
        const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 10;
        const maxWidth = pageWidth - 2 * margin;
  
        const addText = (text, isBold = false) => {
          if (isBold) doc.setFont(undefined, 'bold');
          const lines = doc.splitTextToSize(text, maxWidth);
          lines.forEach(line => {
            if (yPosition > 280) {
              doc.addPage();
              yPosition = 20;
            }
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
          });
          if (isBold) doc.setFont(undefined, 'normal');
          yPosition += 5; // Add some space after each text block
        };
  
        // Add question
        addText(`Question: ${question}`, true);
  
        // Add description
        if (jsonResponse.description) {
          addText(jsonResponse.description);
        }
  
        // Add table data
        if (jsonResponse.table && jsonResponse.table.length > 0) {
          addText("Table Data:", true);
          jsonResponse.table.forEach((row, index) => {
            addText(`${index + 1}.`);
            Object.entries(row).forEach(([key, value]) => {
              addText(`${key}: ${value}`);
            });
          });
        }
  
        // // Add any additional data from the response
        // for (const [key, value] of Object.entries(jsonResponse)) {
        //   if (key !== 'table' && key !== 'description') {
        //     addText(`${key}:`, true);
        //     addText(JSON.stringify(value, null, 2));
        //   }
        // }
  
        doc.save(`${sanitizeFileName(question)}-Donors.pdf`);
        showNotification("PDF export successful!");
        updateNotifications(true, "PDF export successful", new Date().getTime());
      } catch (error) {
        console.error('Failed to export to PDF:', error);
        showNotification(`Failed to export: ${error.message}`);
        updateNotifications(false, "PDF export unsuccessful", new Date().getTime());
      } finally {
        setIsExporting(null);
      }
    }, 4000);
  };

  const exportToWord = (id, question) => {
    logEvent({ action: 'UserExportedResponse', details: 'Export to Word was performed by the user', type: 'word' });
    setIsExporting(id);
    
    setTimeout(() => {
      try {
        const chat = conversation.find(chat => chat.id === id);
        if (!chat || !chat.response || !chat.response.body) {
          showNotification('No response available to export word.');
          return;
        }
  
        const responseText = chat.response.body;
        const jsonResponse = JSON.parse(responseText);
  
        const doc = new Document({
          sections: [{
            children: [
              new Paragraph({
                text: `Question: ${question}`,
                heading: HeadingLevel.HEADING_1
              }),
              new Paragraph({
                text: jsonResponse.description ,
                style: "normalParagraph"
              }),
              new Paragraph({
                text: "Table Data:",
                heading: HeadingLevel.HEADING_2
              })
            ]
          }]
        });
  
        if (jsonResponse.table && jsonResponse.table.length > 0) {
          const keys = Object.keys(jsonResponse.table[0]);
          const table = new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: keys.map(key => 
                  new TableCell({
                    children: [new Paragraph(key)],
                    shading: {
                      fill: "CCCCCC",
                    },
                  })
                ),
              }),
              ...jsonResponse.table.map(row => 
                new TableRow({
                  children: keys.map(key => 
                    new TableCell({
                      children: [new Paragraph(row[key] ? row[key].toString() : '')],
                    })
                  ),
                })
              ),
            ],
          });
  
          doc.addSection({
            children: [table]
          });
        }
  
        // Add any additional data from the response
        // for (const [key, value] of Object.entries(jsonResponse)) {
        //   if (key !== 'table' && key !== 'description') {
        //     doc.addSection({
        //       children: [
        //         new Paragraph({
        //           text: key,
        //           heading: HeadingLevel.HEADING_2
        //         }),
        //         new Paragraph({
        //           text: JSON.stringify(value, null, 2),
        //           style: "normalParagraph"
        //         })
        //       ]
        //     });
        //   }
        // }
  
        Packer.toBlob(doc).then(blob => {
          saveAs(blob, `${sanitizeFileName(question)}-Donors.docx`);
          updateNotifications(true, "Word export successful", new Date().getTime());
        });
  
        showNotification("Word export successful!");
      } catch (error) {
        console.error('Failed to export to Word:', error);
        showNotification(`Failed to export: ${error.message}`);
        updateNotifications(false, "Word export failed: " + error.message, new Date().getTime());
      } finally {
        setIsExporting(null);
      }
    }, 4000);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; s.length > i; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
 
  const sanitizeFileName = (name) => {
    return name.replace(/[<>:"\/\\|?*]/g, "_");
  };
  const showNotification = (message) => {
    // setNotification(message);
    setTimeout(() => {
      setNotification("");
    }, 5000);
  };
 
  const handleFeedback = (isPositive) => {
    const feedbackType = isPositive ? 'Positive' : 'Negative';
    //console.log("inside feedback" )
    // Log the feedback action
    // logEvent({
    //   message: `User${feedbackType}Feedback`,
      
    // });

    // Log the question and response to CloudWatch
    // logEvent({
    //   action: 'FeedbackDetails',
    //   details: `Question: ${chat.question}\nResponse: ${chat.response?.body}`,
    //   type: 'feedback'
    // });

    // Log the query/question from the prompt box
    logEvent({
      action: 'FeedbackQuery',
      details: `Query associated with ${feedbackType.toLowerCase()} feedback: ${chat.question}`,
      type: 'feedback'
    });

    // Set the feedback message
    setFeedbackMessage(`Thank you for your ${feedbackType.toLowerCase()} feedback!`);

    // Clear the feedback message after 3 seconds
    setTimeout(() => {
      setFeedbackMessage("");
    }, 3000);
  };

  useEffect(() => {
    const fetchTokenAndSub = async () => {
      try {
        const token = await getJWT();
        setJwtToken(token);
        const decodedToken = decodeToken(token);
        setSub(decodedToken['sub']);
      } catch (error) {
        console.error('Failed to fetch token:', error);
        logEvent({ eventType: 'fetchTokenError', details: `Error fetching token: ${error.message}` });
      }
    };
    fetchTokenAndSub();
  }, []);

  const formatDateWithWeekday = (date) => {
    const options = { weekday: 'short', hour: '2-digit', minute: '2-digit' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  
  const PinButton = ({ handlePinToggle, pinnedQueries, queryText }) => (
    <button onClick={() => handlePinToggle(queryText)} className="ml-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M9.68894 0.902694L13.098 4.31179C14.0356 5.24941 13.7791 6.83004 12.5931 7.4231L9.15212 9.14355C9.02828 9.2055 8.93355 9.31351 8.88834 9.44449L7.87157 12.3876C7.65858 13.0041 6.87373 13.1849 6.4125 12.7238L4.21939 10.5306L1.08333 13.6667H0.333984L0.334041 12.9174L3.4701 9.78132L1.27698 7.58819C0.815772 7.12696 0.996556 6.34211 1.61306 6.12912L4.55626 5.1124C4.68719 5.06716 4.7952 4.97246 4.85715 4.84856L6.57759 1.4076C7.17064 0.221578 8.75129 -0.0349285 9.68894 0.902694ZM12.3487 5.06107L8.93962 1.65198C8.51343 1.22579 7.79492 1.34239 7.52541 1.88148L5.80496 5.32244C5.6191 5.69414 5.29506 5.97829 4.90225 6.114L2.2259 7.03851L6.96217 11.7748L7.88675 9.09848C8.02246 8.70563 8.30659 8.38166 8.67824 8.19579L12.1192 6.47527C12.6583 6.20577 12.7749 5.48727 12.3487 5.06107Z" fill="#8E8E8E"/>
      </svg>
    </button>
  );

  // useEffect(() => {
  //   if (tableRef.current) {
  //     tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  //   }
  // }, [response]);

  const ErrorMessage = () => (
    <p
      style={{
        color: '#000',
        fontFamily: 'Inter, sans-serif',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        padding: '10px',
        borderRadius: '7px 14px 14px 14px',
        border: '1px solid #D9D9D9',
        background: '#FFF',
      }}
    >
      {responseError !== '' ? responseError : 
        'Request is missing key information needed to process accurate results. Please clarify or rephrase and try again.'
      }
      {/* {responseError} */}
    </p>
  );
  
  const IconWrapper = ({ children, title, onClick }) => (
    <div
      className="cursor-pointer"
      title={title}
      onClick={onClick}
      style={{ width: '20px', height: '20px', marginRight: '10px' }}
    >
      {children}
    </div>
  );
 
// Add this handler function where needed
const handleTableClick = () => {
  setIsTablePopup(!isTablePopup);
};

const scrollbarClasses = `
  h-[325px] 
  overflow-auto 
  
  /* Hide scrollbar by default */
  [&::-webkit-scrollbar]:w-0
  hover:[&::-webkit-scrollbar]:w-2
  
  /* Smooth transition */
  [&::-webkit-scrollbar]:transition-[width]
  [&::-webkit-scrollbar]:duration-300
  [&::-webkit-scrollbar]:ease-in-out
  
  /* Thumb and track styles */
  [&::-webkit-scrollbar-thumb]:bg-white 
  [&::-webkit-scrollbar-thumb]:rounded-full 
  [&::-webkit-scrollbar-track]:bg-gray-100 
  
  /* Firefox support */
  scrollbar-none
  hover:scrollbar-thin 
  scrollbar-thumb-gray-400 
  scrollbar-track-gray-100
`;

const ChevronRightIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    fill="none"
    style={{ cursor: 'pointer' }}
    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
  >
    <path 
        d="M6 4L10 8L6 12" 
        stroke="#8E8E8E" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
    />
  </svg>
);

const DownloadIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="21" 
    viewBox="0 0 20 21" 
    fill="none"
  >
    <path d="M17.5 12.7334V16.0667C17.5 16.5088 17.3244 16.9327 17.0118 17.2452C16.6993 17.5578 16.2754 17.7334 15.8333 17.7334H4.16667C3.72464 17.7334 3.30072 17.5578 2.98816 17.2452C2.67559 16.9327 2.5 16.5088 2.5 16.0667V12.7334" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.83203 8.56689L9.9987 12.7336L14.1654 8.56689" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.7334V2.7334" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

// Add this component inside your ConversationCard component
const TablePopupView = ({ response, isVisible, onClose }) => {
  if (!isVisible) return null;

  // Function to transform header text
  const getTransformedHeader = (header) => {
    switch (header) {
      case 'Full Name':
        return 'Name';
      case 'Phone Number':
        return 'Number';
      case 'Total Cycle Contribution':
        return 'Cycle Total';
      case 'Primary Election Contribution':
        return 'Primary';
      case 'General Election Contribution':
        return 'General';
      default:
        return header;
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="relative bg-white rounded-lg shadow-xl transform transition-all overflow-hidden"
        style={{
          width: 'auto',
          maxWidth: '90vw',
          maxHeight: '90vh',
        }}
        onClick={e => e.stopPropagation()}
      >
        {/* Close button */}
        <button           
          onClick={onClose}           
          className="absolute right-4 top-4 z-20 p-1 rounded-full hover:bg-gray-100 transition-colors duration-150"         
        >           
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#8E8E8E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLineJoin="round"
          >
            <path d="M3 3 L9 9 M3 9 L9 9 L9 3" />
            <path d="M21 21 L15 15 M21 15 L15 15 L15 21" />
          </svg>
        </button>

        <div
          className="overflow-auto max-h-[90vh] rounded-lg"
          style={{
            scrollbarWidth: 'thin',
            scrollbarColor: '#CBD5E0 #EDF2F7',
          }}
        >
          <div className="min-w-[1200px] p-4">
            <table className="min-w-full rounded-[14px] border border-[#D9D9D9] bg-white">
              <thead className="bg-white sticky top-0 z-10">
                <tr className="border-b border-[#D9D9D9]">
                {Object.keys(response.table[0]).filter(header => header !== 'Zip Code').map((header, index) => ( // {{ edit_1 }}
            <th
                      key={index}
                      className="px-3 py-4 bg-white border-b border-[#D9D9D9]"
                      style={{
                        color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
                        fontFamily: 'Inter',
                        fontSize: '13px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '120%'
                      }}
                    >
                      <div className="flex items-center min-h-[24px]">
                        <span className="text-xs">
                          {getTransformedHeader(header)}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {response.table.map((row, rowIndex) => (
                  <tr key={rowIndex} className="hover:bg-gray-50 border-b border-[#D9D9D9] last:border-b-0">
                    {Object.entries(row).filter(([key]) => key !== 'Zip Code').map(([key, cell], cellIndex) => ( // {{ edit_2 }}
                      <td 
                        key={cellIndex} 
                        className={`px-3 py-4 ${cellIndex === 0 ? '' : 'truncate'}`}
                      >
                        
                        <div
                          className={`text-xs font-inter ${cellIndex === 0 ? 'whitespace-normal' : 'truncate'}`}
                          style={{ 
                            color: '#000',
                            ...(cellIndex === 0 && {
                              display: '-webkit-box',
                              WebkitLineClamp: '4',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden'
                            })
                          }}
                        >
                          {(() => {
                            // Check if the cell content is an email
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (emailRegex.test(cell)) {
                              return <EmailLink email={cell} />;
                            }
                            return cell; // Return the cell content as is if not an email
                          })()}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Custom scrollbar styles */}
        <style jsx global>{`
          .overflow-auto::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
          .overflow-auto::-webkit-scrollbar-track {
            background: #EDF2F7;
            border-radius: 4px;
          }
          .overflow-auto::-webkit-scrollbar-thumb {
            background: #CBD5E0;
            border-radius: 4px;
          }
          .overflow-auto::-webkit-scrollbar-thumb:hover {
            background: #A0AEC0;
          }
        `}</style>
      </div>
    </div>
  );
};

const HeaderCell = ({ header }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const words = header.split(' ');
  const shouldTruncate = words.length > 2;
  
  return (
      <th 
        scope="col"
        className="px-4 py-3 text-left text-[13px] font-bold text-[#8E8E8E] uppercase relative group"
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      >

      <div className={`${isExpanded ? '' : 'overflow-hidden'}`}>
        <span className={`
          inline-block
          ${!isExpanded && shouldTruncate ? 'truncate max-w-[80px]' : ''}
          ${isExpanded ? 'whitespace-normal' : 'whitespace-nowrap'}
        `}>
          {shouldTruncate ? `${words.slice(0, 2).join(' ')}..` : header}
        </span>
      </div>
      {shouldTruncate && !isExpanded && (
        <div className="absolute hidden group-hover:block bg-black text-white p-2 rounded-md text-xs whitespace-normal z-10 min-w-[200px] max-w-[300px] top-full left-0 mt-1">
          {header}
        </div>
      )}
    </th>
  );
};

const EmailLink = ({ email, children }) => {
  return (
    <a href={`mailto:${email}`} className="text-[0.8125rem] font-inter hover:underline">
      {children || email}
    </a>
  );
};

  return (
    <div className="w-full mb-4">
      <div className="p-4">
        <div className={`overflow-hidden flex ${isResponse ? 'justify-start' : 'justify-end'}`}>
          {isResponse ? (
            <div className="flex-shrink-0 mr-4">
            <div className="flex flex-col items-start mb-1">
              <img 
                src="response2.svg" 
                alt="User icon" 
                // style={{
                //   display: 'flex',
                //   width: '40px',
                //   height: '40px',
                //   padding: '8px',
                //   justifyContent: 'center',
                //   alignItems: 'center',
                //   gap: '10px',
                //   borderRadius: 'var(--14px-Radious, 14px)',
                //   border: '1px solid var(--foundation-primary-yellow-light-active, #F9E6B6)',
                //   background: 'var(--Foundation-Primary-Yellow-Light, #FDF7E7)'
                // }}
              />
            </div>
          </div>
          ) : (
            <div className="flex-shrink-0 ml-2 order-2 justify-end">
          <div 
            style={{
              display: 'flex',
              width: '40px',
              height: '40px',
              padding: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              borderRadius: 'var(--14px-Radious, 14px)',
              border: '1px solid var(--foundation-alert-green-light-active, #C4EDE6)',
              background: 'var(--foundation-alert-green-light-hover, #E2F6F3)'
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" 
                stroke="#40C5AF" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
              <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" 
                stroke="#40C5AF" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

          )}
          <div className={`overflow-y-auto flex-grow ${isResponse ? 'ml-0.5' : 'mr-4 order-1'}`}>
          
          {isResponse ? (
            <div>      
              <>
              <div className="inline-block w-[1000px]">
              <div className="inline-block w-[1000px] flex items-center justify-between">
                <span className={`text-xs ${response?.error ? 'text-[#8E8E8E]' : 'text-[#8E8E8E]'}`}>
                  {formatDateWithWeekday(chat.date)}
                </span>
                
              </div>
  
                {isLoading ? (
                  <div className="p-2.5 rounded-[14px] bg-[#F2F2F2]">
                    <span>.</span>
                  </div>
                ) : response?.error ? (
                  <div className="p-2.5 rounded-[14px] ">
                    <ErrorMessage message={response.error.message} />
                  </div>
                ) : (
                  <div className={`pt-2.5 flex flex-col rounded-[14px] w-[120%] ${response?.error ? 'border-red-600' : 'border-white'} whitespace-nowrap`}> 
                  {response?.table ? (  
                    <div 
                      ref={tableRef} 
                      className={`w-full h-auto min-h-[325px] rounded-lg bg-white ${scrollbarClasses}`}
                    >
                      {/* {response?.table ? ( */}
                        {/* <> */}
                        
                        <div className="w-full relative" >
                        <div style={{ width: '100%', height: 'auto' }}>
                            <div className="inline-block min-w-full align-middle">
                              <table className="min-w-full bg-white ">
                              <thead className="bg-[#FFFFFF] sticky top-0 z-10">
                              <tr className="border-b-2 border-solid border-gray-300 relative">
                                {Object.keys(response.table[0]).map((header, index) => {
                                  // Skip zip code column
                                  if (header === 'Zip Code') return null;
                                        // Transform header text
                                        let displayHeader = header;
                                        if (header === 'Full Name') displayHeader = 'Name';
                                        else if (header === 'Phone Number') displayHeader = 'Number';
                                        else if (header === 'Total Cycle Contribution') displayHeader = 'Cycle Total';
                                        else if (header === 'Primary Election Contribution') displayHeader = 'Primary';
                                        else if (header === 'General Election Contribution') displayHeader = 'General';
                                        else if (header === 'Street Address') displayHeader = 'Address';
                                        return (
                                          <th 
                                            key={index} 
                                            className="px-1.5 py-4"
                                            style={{
                                              color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
                                              fontFamily: 'Inter',
                                              fontSize: '13px',
                                              fontStyle: 'normal',
                                              fontWeight: '700',
                                              lineHeight: '120%'
                                            }}
                                          >
                                            <div className="flex items-center min-h-[24px] group relative">
                                              <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] font-bold leading-[120%]">
                                                {typeof displayHeader === 'string' && displayHeader.length > (index === 0 ? 15 : 10)
                                                  ? `${displayHeader.slice(0, index === 0 ? 15 : 11)}`
                                                  : displayHeader}
                                              </span>
                                              {/* Hover tooltip */}
                                              {typeof header === 'string' && header.length > (index === 0 ? 15 : 10) && (
                                                <div className="absolute hidden group-hover:block bg-gray-900 text-white text-xs rounded px-2 py-1 left-0 -bottom-8 whitespace-nowrap z-10">
                                                  {header}
                                                </div>
                                              )}
                                            </div>
                                          </th>
                                        );
                                      })}
                                      <div 
                                        className="cursor-pointer p-1 hover:bg-gray-100 rounded-md ml-auto" 
                                        onClick={handleTableClick}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#8E8E8E"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLineJoin="round"
                                        >
                                          <polyline points="14 3 21 3 21 10"></polyline>
                                          <line x1="14" y1="10" x2="21" y2="3"></line>
                                          <polyline points="3 14 3 21 10 21"></polyline>
                                          <line x1="3" y1="21" x2="10" y2="14"></line>
                                        </svg>
                                      </div>
                                    </tr>
                                  </thead>
                                  <tbody className="bg-white overflow-auto">
                                    {response.table.slice(startIndex, endIndex).map((row, rowIndex) => (
                                      <tr key={rowIndex} className="hover:bg-gray-50 border-b border-[#F2F2F2] last:border-b-0">
                                        {Object.entries(row).map(([key, cell], cellIndex) => {
                                          // Skip zip code column
                                          if (key === 'Zip Code') return null;
                                          
                                          return (
                                          <td key={cellIndex} className="px-1.5 py-4">
                                            <div
                                              className={`text-[0.8125rem] font-inter ${
                                                showFullText[`row-${rowIndex}-col-${cellIndex}`]
                                                  ? ''
                                                  : 'truncate'
                                              }`}
                                              style={{ color: '#000' }}
                                              onClick={() =>
                                                setShowFullText({
                                                  ...showFullText,
                                                  [`row-${rowIndex}-col-${cellIndex}`]: !showFullText[
                                                    `row-${rowIndex}-col-${cellIndex}`
                                                  ],
                                                })
                                              }
                                            >
                                              
                                              {/* {(() => {
                                                if (typeof cell === 'string') {
                                                  // Capitalize first letter of each word, rest lowercase
                                                  let processedCell = cell.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
                                                  
                                                  if (showFullText[`row-${rowIndex}-col-${cellIndex}`]) {
                                                    // Show full text when expanded
                                                    return processedCell;
                                                  } else {
                                                    // Truncate when not expanded
                                                    const limit = cellIndex === 0 ? 25 : 10;
                                                    return processedCell.length > limit
                                                      ? `${processedCell.slice(0, limit)}..`
                                                      : processedCell;
                                                  }
                                                }
                                                // Return non-string cells as is
                                                return cell;
                                              })()} */}
                                              {(() => {
                                                if (typeof cell === 'string') {
                                                  // If the column is "State", display in uppercase
                                                  if (key === 'State') {
                                                    return cell.toUpperCase();
                                                  }
                                                  let processedCell = cell.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
                                                  
                                                  // Check if the cell content is an email
                                                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                  if (emailRegex.test(processedCell)) {
                                                    return <EmailLink email={processedCell} />;
                                                  }
                                                  
                                                  // Rest of your existing logic for non-email cells
                                                  if (showFullText[`row-${rowIndex}-col-${cellIndex}`]) {
                                                    return processedCell;
                                                  } else {
                                                    const limit = cellIndex === 0 ? 25 : 10;
                                                    return processedCell.length > limit
                                                      ? `${processedCell.slice(0, limit)}..`
                                                      : processedCell;
                                                  }
                                                }
                                                return cell;
                                              })()}
                                            </div>
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          </div>

                          <TablePopupView
                            response={response}
                            startIndex={startIndex}
                            endIndex={endIndex}
                            isVisible={isTablePopup}
                            onClose={() => setIsTablePopup(false)}
                          />
                        {/* </> */}
                      </div>
                      ) : 
                      (
                        <ErrorMessage />
                      )}
                    {/* </div> */}
                    {response?.table?.length > 0 && (
                      <div className="flex justify-between items-center">
                      {/* Export and feedback buttons moved to left side */}
                      <div className="flex items-center">
                          {/* <div style={{ width: '40px' }}></div> */}
                          {isExporting === chat.id ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              style={{ marginRight: '10px' }}
                              size="lg"
                              spin
                            />
                          ) : (
                            <div className="flex items-center space-x-2">
                              {chat.response?.body && (
                                <>
                                  <div className="flex items-center space-x-4">
                                    <div 
                                      style={{ cursor: 'pointer', width: '16px', height: '16px' }}
                                      title="Positive Feedback"
                                      onClick={() => handleFeedback(true)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M4.66732 7.83337L7.33398 1.83337C7.86442 1.83337 8.37313 2.04409 8.7482 2.41916C9.12327 2.79423 9.33398 3.30294 9.33398 3.83337V6.50004H13.1073C13.3006 6.49785 13.492 6.53771 13.6684 6.61685C13.8447 6.696 14.0017 6.81253 14.1286 6.95838C14.2554 7.10424 14.349 7.27592 14.4029 7.46154C14.4568 7.64716 14.4697 7.84228 14.4407 8.03337L13.5207 14.0334C13.4724 14.3513 13.3109 14.6411 13.0659 14.8494C12.8209 15.0576 12.5089 15.1703 12.1873 15.1667H4.66732M4.66732 7.83337V15.1667M4.66732 7.83337H2.66732C2.3137 7.83337 1.97456 7.97385 1.72451 8.2239C1.47446 8.47395 1.33398 8.81309 1.33398 9.16671V13.8334C1.33398 14.187 1.47446 14.5261 1.72451 14.7762C1.97456 15.0262 2.3137 15.1667 2.66732 15.1667H4.66732"
                                          stroke="#8E8E8E"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div 
                                      style={{ cursor: 'pointer', width: '16px', height: '16px' }}
                                      title="Negative Feedback"
                                      onClick={() => handleFeedback(false)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M11.1224 9.16695L8.45575 15.167C7.92532 15.167 7.41661 14.9562 7.04154 14.5812C6.66646 14.2061 6.45575 13.6974 6.45575 13.167V10.5003H2.68242C2.48914 10.5025 2.29771 10.4626 2.12137 10.3835C1.94503 10.3043 1.78801 10.1878 1.66118 10.0419C1.53435 9.89609 1.44075 9.7244 1.38686 9.53878C1.33297 9.35316 1.32008 9.15804 1.34908 8.96695L2.26908 2.96695C2.3173 2.64901 2.47879 2.35921 2.72381 2.15094C2.96883 1.94268 3.28086 1.82998 3.60242 1.83362H11.1224M11.1224 9.16695V1.83362M11.1224 9.16695H12.9024C13.2797 9.17362 13.6463 9.04153 13.9327 8.79574C14.219 8.54995 14.4052 8.20758 14.4557 7.83362V3.16695C14.4052 2.79299 14.219 2.45062 13.9327 2.20483C13.6463 1.95904 13.2797 1.82694 12.9024 1.83362H11.1224"
                                          stroke="#8E8E8E"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  
                                  <div 
                                    className="flex items-center " 
                                    style={{ 
                                      display: 'flex', 
                                      alignItems: 'flex-end', 
                                      gap: '16px' 
                                    }}
                                  >
                                    <div className="flex items-center">
                                      <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="20" 
                                        height="21" 
                                        viewBox="0 0 20 21" 
                                        fill="none"
                                      >
                                        <path d="M17.5 12.7334V16.0667C17.5 16.5088 17.3244 16.9327 17.0118 17.2452C16.6993 17.5578 16.2754 17.7334 15.8333 17.7334H4.16667C3.72464 17.7334 3.30072 17.5578 2.98816 17.2452C2.67559 16.9327 2.5 16.5088 2.5 16.0667V12.7334" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.83203 8.56689L9.9987 12.7336L14.1654 8.56689" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10 12.7334V2.7334" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                      <ChevronRightIcon />
                                      
                                      {isDropdownOpen && (
                                        
                                        <div className="flex items-center ml-2 gap-2">
                                          <div 
                                            title="Export to Excel" 
                                            onClick={() => {
                                              exportToExcel(chat.id, chat.question);
                                              setIsDropdownOpen(false);
                                            }}
                                            className="cursor-pointer"
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
                                              <path fill="#4CAF50" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"/>
                                              <path fill="#FFF" d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"/>
                                              <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z"/>
                                              <path fill="#FFF" d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"/>
                                            </svg>
                                          </div>
                                          <div 
                                            title="Export to PDF" 
                                            onClick={() => {
                                              exportToPDF(chat.id, chat.question);
                                              setIsDropdownOpen(false);
                                            }}
                                            className="cursor-pointer"
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
                                              <path fill="#FF5722" d="M40 45L8 45 8 3 30 3 40 13z"/>
                                              <path fill="#FBE9E7" d="M38.5 14L29 14 29 4.5z"/>
                                              <path fill="#FFF" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"/>
                                            </svg>
                                          </div>
                                          <div 
                                            title="Export to Word" 
                                            onClick={() => {
                                              exportToWord(chat.id, chat.question);
                                              setIsDropdownOpen(false);
                                            }}
                                            className="cursor-pointer"
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
                                              <path fill="#2196F3" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"/>
                                              <path fill="#FFF" d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"/>
                                              <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z"/>
                                              <path fill="#FFF" d="M21.174,31l-2.136-8.318l-2.097,8.318h-2.663l-3.074-11.992h2.576l1.822,8.685l2.097-8.685h2.386l2.175,8.685l1.822-8.685h2.576L23.797,31H21.174z"/>
                                            </svg>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="flex justify-center items-center p-2.5 gap-2.5">
                          <div className="flex items-center gap-2.5">
                            {/* Added fixed-width container */}
                            <div className="w-[140px] text-right">
                              <span className="text-[#8E8E8E] font-inter text-[13px] font-normal leading-[120%]">
                                {startIndex + 1} - {Math.min(endIndex, response?.table?.length)} of {response?.table?.length} items
                              </span>
                            </div>
                            <div className="flex items-center gap-2.5">
                              <button
                                className="flex justify-center items-center px-2 py-1 gap-2.5
                                  text-gray-600 hover:bg-gray-100
                                  disabled:opacity-50 disabled:cursor-not-allowed
                                  w-8 h-8"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={disablePreviousButton}
                              >
                                <ChevronLeft size={20} />
                              </button>
                              <button
                                className="flex justify-center items-center px-2 py-1 gap-2.5
                                  text-gray-600 hover:bg-gray-100
                                  disabled:opacity-50 disabled:cursor-not-allowed
                                  w-8 h-8"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={disableNextButton}
                              >
                                <ChevronRight size={20} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                    )}
                  </div>
                </>
              </div>
            ) : (
              <div className="flex flex-col items-end w-full">
                <div className="mb-1 flex items-center justify-end">
                  <span className="text-xs text-[#8E8E8E] mr-2">
                    {formatDateWithWeekday(chat.date)}
                  </span>
                  {!isPinned && !isResponse && !isEditing && (
                    <PinButton
                      handlePinToggle={handlePinToggle}
                      pinnedQueries={pinnedQueries}
                      queryText={chat.question}
                    />
                  )}
                </div>
                <div className="p-2.5 rounded-[0.875rem_0.4375rem_0.875rem_0.875rem] border border-[#D9D9D9] bg-white inline-block max-w-full">
                  <p className="text-black break-words text-right">{chat.question}</p>
                </div>
              </div>
            )}
          </div>
        </div>
  
        {isModalOpen && (
          <Modal onClose={() => setModalOpen(false)}>
            <ModalContent 
              pinnedQueries={pinnedQueries}
              queryText={queryText}
              setPinnedQueries={setPinnedQueries}
              setModalOpen={setModalOpen}
              deleteQuery={deleteQuery}
              addQuery={addQuery}
            />
          </Modal>
        )}
  
        {feedbackMessage && (
          <div className="mt-2 p-2 bg-blue-100 text-blue-700 rounded">
            {feedbackMessage}
          </div>
        )}
      </div>
    </div>
  );
};
export default ConversationCard;