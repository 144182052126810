import React from 'react';
import { Construction } from 'lucide-react';
import Sidebar_v2 from '../sidebar/Sidebar_v2';

const themeStyles = {
  backgroundColor: '#FFF',
  color: '#000',
  minHeight: '100vh',
  display: "flex",
  width: "100%"
};

const UnderConstruction = () => {
  return (
    <div style={themeStyles}>
      <Sidebar_v2 />
      <div className="flex-1 flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <Construction size={100} className="text-yellow-500 mb-4 mx-auto" />
          <h1 className="text-4xl md:text-6xl font-bold text-gray-800 mb-4">
            Under Construction
          </h1>
          <p className="text-xl md:text-2xl text-gray-600 max-w-2xl mx-auto px-4">
            We're working hard to bring you something amazing!
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;