/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import {CheckCircle} from 'lucide-react';
import AWS from 'aws-sdk';
import { Amplify } from 'aws-amplify';
import { awsExports } from '../../aws-exports';
import {
  jwtTokenState, 
  rolesState,
  subState,
  tenantGroupState,
  userEmailState,
  isSuperAdminState,
  isTenantAdminRoleState
} from '../../store/atoms';
Amplify.configure(awsExports);

const InputField = ({ label, placeholder, type = 'text', value, onChange, disabled, className }) => {
  return (
    <div className="relative mb-4">
      <div className="relative">
        <input
          type={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          className={`
            w-full px-3 pt-5 pb-2 text-sm border rounded-md
            focus:outline-none focus-within:ring-1 focus-within:ring-black focus-within:border-black
            transition-all duration-200
            ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}
            ${className}
          `}
          aria-label={label}
        />
        <label
          className={`
            absolute text-xs text-gray-500 left-3 top-1
            transition-all duration-200
            ${disabled ? 'text-gray-400' : ''}
          `}
        >
          {label}
        </label>
      </div>
    </div>
  );
};
// const InputField = ({ label, placeholder, type = 'text', value, onChange, disabled, className}) => {
//   return (
//     <div className="relative mb-4">
//       <label
//         className={`
//           absolute text-xs text-gray-500 left-3 top-1
//           ${disabled ? 'text-gray-400' : ''}
//         `}
//       >
//         {label}
//       </label>
//       <input
//         type={type}
//         value={value}
//         onChange={onChange}
//         disabled={disabled}
//         placeholder={placeholder}
//         className={`
//           w-full px-3 pt-5 pb-2 text-sm border rounded-md
//           focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300
//           ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}
//           ${className}
//         `}
//         aria-label={label}
//       />
//     </div>
//   );
// };

// const SelectField = ({ label, options, value, onChange, className }) => (
//   <div className="mb-4">
//     <label className="block text-sm font-medium text-gray-700 mb-1">
//       {label}
//     </label>
//     <select
//       value={value}
//       onChange={onChange}
//       className={`w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-amber-300 focus:border-amber-300 ${className}`}
//     >
//       <option value="">Select a team</option>
//       {options.map((option) => (
//         <option key={option} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   </div>
// );

// const SelectField = ({ label, options, value, onChange, className}) => {
//   return (
//     <div className="relative mb-4 px-2.5">
//       <div className={`relative border border-gray-300 rounded-[7px] focus-within:ring-1 focus-within:ring-black focus-within:border-black ${className}`}>
//         <select
//           value={value}
//           onChange={onChange}
//           className="w-full px-3 pt-6 pb-2 text-sm appearance-none focus:outline-none bg-transparent"
//         >
//           <option value="" disabled hidden>Select a team</option>
//           {options.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>
//         <label className="absolute text-xs text-gray-500 top-2 left-3 transition-all duration-300 pointer-events-none">
//           {label}
//         </label>
//         <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
//           <svg className="w-4 h-4 fill-current text-gray-500" viewBox="0 0 20 20">
//             <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" fillRule="evenodd"></path>
//           </svg>
//         </div>
//       </div>
//     </div>
//   );
// };

// const PillButton = ({ label, selected, onClick }) => (
//   <button
//     type="button"
//     onClick={onClick}
//     className={`px-3 py-1 text-sm font-medium rounded-full transition-colors duration-150 ease-in-out ${
//       selected
//         ? 'bg-amber-300 text-white'
//         : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
//     }`}
//   >
//     {label}
//   </button>
// );

// const TabButton = ({ label, selected, onClick }) => (
//   <button
//     type="button"
//     onClick={onClick}
//     className={`
//       flex flex-col justify-center items-center
//       h-10 px-3
//       rounded-[7px]
//       transition-all duration-150 ease-in-out
//       text-center
//       font-inter text-base leading-tight
//       ${
//         selected
//           ? 'bg-[#F2F2F2] text-black font-bold border border-[#D9D9D9]'
//           : 'bg-white text-[#8E8E8E] font-normal border-transparent'
//       }
//       ${
//         !selected
//           ? 'hover:text-black hover:font-bold hover:bg-white'
//           : ''
//       }
//     `}
//   >
//     {label}
//   </button>
// );

// const SubmitButton = ({ onClick, disabled }) => (
//   <button
//     type="button"
//     onClick={onClick}
//     disabled={disabled}
//     className={`w-full sm:w-auto px-4 py-2 text-white font-bold rounded-md ${
//       disabled
//         ? 'bg-gray-500 cursor-not-allowed'
//         : 'bg-amber-500 hover:text-black'
//     }`}
//   >
//     Submit
//   </button>
// );




function EditUserForm({ user, isSuperAdmin }) {
  const [activeTab, setActiveTab] = useState('createTenant');
  const [teamName, setTeamName] = useState('');
  const [teamSize, setTeamSize] = useState(user.customLimit);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [email, setEmail] = useState(user.email);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [message, setMessage] = useState('');
  const [userGroupNames, setUserGroupNames] = useState([]);
  const [errors, setErrors] = useState({});
  //const [lambdaFunctionName, setLambdaFunctionName] = useState('fr-tst-fe-tenantmgmt');
  const [lambdaFunctionName] = useState(awsExports.Lambda.userManagement);
  // const { configureLambda, decodeToken, getJWT } = useLambda();
  // const [formTitle, setFormTitle] = useState('Add New Tenant');

  const validateForm = () => {
    const newErrors = {};
    //console.log('inside the validate function');
    // if (isSuperAdmin && !teamName && activeTab === 'createTenant') {
    //   newErrors.teamName = true;
    // }
    // if (activeTab === 'createTenant' && (teamSize < 1 || teamSize > 50)) {
    //   newErrors.teamSize = true;
    // }
    // if (!email) {
    //   newErrors.email = true;
    // }
    // if (!confirmEmail) {
    //   newErrors.confirmEmail = true;
    // }
    // if (email !== confirmEmail) {
    //   newErrors.confirmEmail = true;
    // }
    // if(!firstName) {
    //   newErrors.firstName = true;
    // }
    // if(!lastName) {
    //   newErrors.lastName = true;
    // }
  
    // setErrors(newErrors);
    // console.log('new errors are: '+JSON.stringify(newErrors));
    // console.log(`validate form return ${Object.keys(newErrors).length === 0}`);
    // return Object.keys(newErrors).length === 0;
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
      try {
        await callLambdaFunction();
        resetForm(); // Reset the form after successful submission
        setTimeout(function() {
          window.location.reload();
      }, 2000);
      } catch (error) {
        console.error('Error submitting form:', error);
        setMessage(`Error: ${error.message}`);
      }
  };

  // const createS3FolderIfNotExists = async (tenantName) => {
  //   try {
  //     const s3 = new AWS.S3({
  //       accessKeyId: awsExports.S3.accessKeyId,
  //       secretAccessKey: awsExports.S3.secretAccessKey,
  //       region: awsExports.S3.region,
  //     });
  
  //     const basePath = `Tenants/${tenantName}/`;
  //     const foldersToCreate = ['ClientUpload/', 'FEC/', 'NGP-VAN/'];
  
  //     // Create the tenant folder
  //     const createTenantFolderParams = {
  //       Bucket: awsExports.S3.bucket,
  //       Key: basePath,
  //       Body: ''
  //     };
  //     await s3.putObject(createTenantFolderParams).promise();
  //     //console.log(`Created tenant folder: ${basePath}`);
  
  //     // Create subfolders
  //     for (const folder of foldersToCreate) {
  //       const folderPath = `${basePath}${folder}`;
  
  //       const createFolderParams = {
  //         Bucket: awsExports.S3.bucket,
  //         Key: folderPath,
  //         Body: ''
  //       };
  //       await s3.putObject(createFolderParams).promise();
  //       //console.log(`Created folder: ${folderPath}`);
  //     }
  //   } catch (error) {
  //     console.error('Error creating S3 folders:', error);
  //     throw error;
  //   }
  // };

  const callLambdaFunction = async () => {
    try {
      // await configureLambda(jwtToken);
      const lambda = new AWS.Lambda();
      const payload = {
        'operation': 'editUser',
        email,
        firstName,
        lastName,
        'tenantLimit': teamSize != null ? teamSize.toString() : '',
      };

      // if (teamName !== null && teamName !== '') {
      //   payload.groupName = teamName;
      // }

      const stringifiedPayload = JSON.stringify(payload);
      //console.log(`PAYLOAD IS : ${stringifiedPayload}`);
      const params = {
        //FunctionName: 'createTenant',
        FunctionName: lambdaFunctionName,
        Payload: stringifiedPayload,
      };

      const result = await lambda.invoke(params).promise();
      const data = JSON.parse(result.Payload);
      const bodyData = JSON.parse(data.body);

      // If a new tenant group is created by a super admin, create the S3 folder
      // if (isSuperAdmin && activeTab === 'createTenant' && teamName) {
      //   await createS3FolderIfNotExists(teamName);
      // }
      //console.log('message is: '+bodyData.message);
      setMessage(bodyData.message || 'Lambda function called successfully');
      //logEvent({ eventType: 'createdUserAndGroup', details: `User and group created. Message: ${bodyData.message}` });
    } catch (error) {
      console.log(`error creating tenant: ${error.message}`);
      setMessage(`Error creating tenant: ${error.message}`);
      //logEvent({ eventType: 'errorCreatingUserAndGroup', details: `Error creating user and group: ${error.message}` });
    }
  };

  // const fetchGroupsForDropdown = async () => {
  //   try {
  //     //await configureLambda(jwtToken);
  //     const lambda = new AWS.Lambda();
  //     const payload = {
  //       'operation': 'fetchGroups',
  //       activeTab,
  //     };
  //     const params = {
  //       //FunctionName: 'createTenant',
  //       FunctionName: lambdaFunctionName,
  //       Payload: JSON.stringify(payload),
  //     };

  //     const result = await lambda.invoke(params).promise();
  //     const data = JSON.parse(result.Payload);
  //     const groupNames = JSON.parse(data.body);
  //     //console.log('group names are : '+groupNames);
  //     setUserGroupNames(groupNames);
  //     //logEvent({ eventType: 'fetchedGroupsForDropdown', details: `Fetched groups for dropdown for tab: ${activeTab}` });
  //   } catch (error) {
  //     console.error('Error fetching groups:', error);
  //     //logEvent({ eventType: 'fetchGroupsForDropdownError', details: `Error fetching groups for dropdown: ${error.message}` });
  //   }
  // };

  // useEffect(() => {
  //   if (activeTab === 'addUser') {
  //     fetchGroupsForDropdown();
  //   }
  // }, [activeTab]);
  //const showLimitExceededMessage = !isSuperAdmin && isReadOnly;

  const resetForm = () => {
    // setTeamName('');
    setTeamSize(1);
    setSelectedTeam('');
    setEmail('');
    setConfirmEmail('');
    setFirstName('');
    setLastName('');
    setErrors({});
  };

  const UserEditedNotification = ({ message }) => {
    return (
      <div className="max-w-md w-full bg-emerald-50 border border-emerald-200 rounded-lg p-4 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <CheckCircle className="h-6 w-6 text-emerald-400" />
        </div>
        <div className="flex-1">
          <p className="font-medium text-emerald-800">User Updated!</p>
          {/* <p className="mt-1 text-emerald-700">{message}</p> */}
        </div>
      </div>
    );
  };


  // useEffect(() => {
  //   if (onFormTypeChange) {
  //     onFormTypeChange(activeTab);
  //   }
  //   setFormTitle(activeTab === 'createTenant' ? 'Add New Tenant' : 'Add New User');
  // }, [activeTab, onFormTypeChange]);

  // const handleTabChange = (tab) => {
  //   setActiveTab(tab);
  //   if (onFormTypeChange) {
  //     onFormTypeChange(tab);
  //   }
  //   //setFormTitle(tab === 'createTenant' ? 'Add New Tenant' : 'Add New User');
  // };
  
  return (
    <div className="w-full mx-auto">
      <form onSubmit={handleSubmit} className="space-y-4">
      {/* {showLimitExceededMessage && (
          <div className="bg-red-100 text-red-800 p-3 rounded text-sm">
            You have exceeded your limit.
          </div>
        )} */}
      {message && (
        <UserEditedNotification message={message} />
      )}
        
        <div className="flex space-x-4 px-2.5 pt-2.5">
          <div className="flex-1">
            <InputField
              label="First name"
              // placeholder={firstName}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              // disabled={!isSuperAdmin && isReadOnly}
              className={errors.lastName ? 'border-red-500' : ''}
            />
          </div>
          <div className="flex-1">
            <InputField
              label="Last name"
              // placeholder={user.lastName}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              // disabled={!isSuperAdmin && isReadOnly}
              className={errors.lastName ? 'border-red-500' : ''}
            />
          </div>
        </div>

        {(isSuperAdmin && user.customAccess === 'tenantAdminRole') ? (
            <div className="w-1/3 relative px-2.5">
              <div className="relative border border-gray-300 rounded-[7px] focus-within:ring-1 focus-within:ring-black focus-within:border-black overflow-hidden">
                <input
                  type="number"
                  min="1"
                  max="50"
                  value={teamSize}
                  onChange={(e) => setTeamSize(Math.min(50, Math.max(1, parseInt(e.target.value) || 1)))}
                  className={`w-full px-3 pt-6 pb-2 text-sm appearance-none focus:outline-none ${errors.teamSize ? 'border-red-500' : ''}`}
                  placeholder=" "
                />
                <label className="absolute text-xs text-gray-500 top-2 left-3 transition-all duration-300 pointer-events-none">
                  Members
                </label>
              </div>
              <span className="text-xs text-gray-400 mt-1 block">Members want to add</span>
            </div>
          ) : null}
        
        {/* <div className='px-2.5'>
        <InputField
          label="User email"
          placeholder="Johndoe@gmail.com"
          type="email"
          value={email}
          disabled={!isSuperAdmin && isReadOnly}
          onChange={(e) => setEmail(e.target.value)}
          className={errors.email ? 'border-red-500' : ''}
        />
        
        <InputField
          label="Confirm User email"
          placeholder="Johndoe@gmail.com"
          type="email"
          value={confirmEmail}
          disabled={!isSuperAdmin && isReadOnly}
          onChange={(e) => setConfirmEmail(e.target.value)}
          className={errors.confirmEmail ? 'border-red-500' : ''}
        />
        </div> */}
        
        
        <div className="flex justify-end px-2.5">
          <button
            type="submit"
            onClick={handleSubmit}
            className={`px-4 py-2 bg-yellow-500 text-white font-semibold rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-30`}
          >
            Edit user
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditUserForm;
