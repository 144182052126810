import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk';
import { awsExports } from '../../aws-exports';
import useLambda from '../hooks/useLambda';
import { json, useNavigate } from 'react-router-dom';
import { logEvent } from '../../cloudwatchLogger'; // Import the logEvent function
import {
  fetchUserAttributes,
} from "@aws-amplify/auth";

// const user = await getCurrentUser();
// const userAttributes = await fetchUserAttributes();
// console.log('user: '+JSON.stringify(user));
// console.log('userAttribute :'+userAttributes);


Amplify.configure(awsExports);

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('createTenant');
  const [userEmail, setUserEmail] = useState('');
  const [userGroupNames, setUserGroupNames] = useState([]);
  const [tenantsUsers, setTenantsUsers] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [tenantLimit, setTenantLimit] = useState('');
  const [loggedInUserLimit, setLoggedInUserLimit] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminUsername, setAdminUsername] = useState('');
  const [message, setMessage] = useState('');
  const [roles, setRoles] = useState([]);
  const [sub, setSub] = useState('');
  const [tenantGroup, setTenantGroup] = useState([]);
  const { configureLambda, decodeToken, getJWT } = useLambda();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  

  const isSuperAdmin = roles.includes('arn:aws:iam::696416640413:role/superAdminRole');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setGroupName(''); // Reset fields when switching tabs
    setAdminEmail('');
    setAdminUsername('');
    logEvent({ eventType: 'tabChanged', details: `User changed to tab: ${tab}` });
  };

  const fetchTenantUsers = async () => {
    try {
      setLoading(true);
      await configureLambda(jwtToken);
      const lambda = new AWS.Lambda();
      const payload = {
        'operation': 'fetchTenantUsers',
        isSuperAdmin,
        tenantGroup,
        userEmail,
      };
      const params = {
        FunctionName: 'createTenant',
        Payload: JSON.stringify(payload),
      };

      const result = await lambda.invoke(params).promise();
      const data = JSON.parse(result.Payload);
      const users = JSON.parse(data.body);
      setTenantsUsers(users);
      setLoading(false);
      logEvent({ eventType: 'fetchedTenantUsers', details: `Fetched tenant users for user: ${userEmail}` });
    } catch (error) {
      console.error('Error fetching tenant users:', error);
      setLoading(false);
      logEvent({ eventType: 'fetchTenantUsersError', details: `Error fetching tenant users: ${error.message}` });
    }
  };

  const fetchGroupsForDropdown = async () => {
    try {
      await configureLambda(jwtToken);
      const lambda = new AWS.Lambda();
      const payload = {
        'operation': 'fetchGroups',
        activeTab,
      };
      const params = {
        FunctionName: 'createTenant',
        Payload: JSON.stringify(payload),
      };

      const result = await lambda.invoke(params).promise();
      const data = JSON.parse(result.Payload);
      const groupNames = JSON.parse(data.body);
      setUserGroupNames(groupNames);
      logEvent({ eventType: 'fetchedGroupsForDropdown', details: `Fetched groups for dropdown for tab: ${activeTab}` });
    } catch (error) {
      console.error('Error fetching groups:', error);
      logEvent({ eventType: 'fetchGroupsForDropdownError', details: `Error fetching groups for dropdown: ${error.message}` });
    }
  };

  const fetchLimit = async () => {
    //console.log('is superadmin: ' + isSuperAdmin + ' jwt token is: ' + jwtToken);
    setIsLoading(true);
    if (!isSuperAdmin) {
      try {
        await configureLambda(jwtToken);
        const lambda = new AWS.Lambda();
        const payload = {
          'operation': 'fetchLimit',
          userEmail,
          tenantGroup,
        };
        //console.log('payload of fetchGroups is: ' + JSON.stringify(payload));
        const params = {
          FunctionName: 'createTenant',
          Payload: JSON.stringify(payload),
        };

        const result = await lambda.invoke(params).promise();
        const data = JSON.parse(result.Payload);
        const groupLimit = JSON.parse(data.body);
        //console.log('group limit is: ' + groupLimit.size);
        setLimit(groupLimit.size);
        logEvent({ eventType: 'fetchedLimit', details: `Fetched limit for user: ${userEmail}` });
      } catch (error) {
        console.error('Error fetching the limit:', error);
        logEvent({ eventType: 'fetchLimitError', details: `Error fetching the limit: ${error.message}` });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const userLimit = async () => {
    try {
      // Fetch user attributes
      const userAttributes = await fetchUserAttributes();
      const tenantLimitAttribute = userAttributes['custom:tenantLimit'];
      setLoggedInUserLimit(tenantLimitAttribute);
    } catch (error) {
      console.error('Error fetching user attributes:', error);
      return null;
    }
  };
  

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const jwtToken = await getJWT();
        setJwtToken(jwtToken);
        const decodedToken = decodeToken(jwtToken);
        const cognitoRoles = decodedToken['cognito:roles'];
        const subAttribute = decodedToken['sub'];
        const tenantgroup = decodedToken['cognito:groups'];
        //console.log('tenant groups: '+tenantgroup);
        const email = decodedToken['email'];
        let filteredGroups = [];
        if (tenantgroup.includes("Tenants")) {
          filteredGroups = tenantgroup.filter(group => group !== 'Tenants');
        } else if (tenantgroup) {
          filteredGroups = [...tenantgroup];
        }
        setUserEmail(email);
        setRoles(cognitoRoles);
        setSub(subAttribute);
        setTenantGroup(filteredGroups);
        
        logEvent({ eventType: 'fetchedRoles', details: `Fetched roles for user: ${email}` });
        // const user = await getCurrentUser();
        

      } catch (error) {
        console.error('Failed to fetch roles:', error);
        logEvent({ eventType: 'fetchRolesError', details: `Error fetching roles: ${error.message}` });
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    if (activeTab === 'addUser') {
      fetchGroupsForDropdown();
    }
  }, [activeTab]);

  useEffect(() => {
    userLimit();
    if (jwtToken) {
      fetchLimit();
    }
  }, [jwtToken,limit]);

  const callLambdaFunction = async () => {
    try {
      await configureLambda(jwtToken);
      const lambda = new AWS.Lambda();
      const payload = {
        'operation': 'createUserAndGroup',
        activeTab,
        roles,
        adminEmail,
        adminUsername,
        sub,
        tenantGroup,
        tenantLimit,
      };

      if (groupName !== null && groupName !== '') {
        payload.groupName = groupName;
      }

      const stringifiedPayload = JSON.stringify(payload);
      const params = {
        FunctionName: 'createTenant',
        Payload: stringifiedPayload,
      };

      const result = await lambda.invoke(params).promise();
      const data = JSON.parse(result.Payload);
      const bodyData = JSON.parse(data.body);
      setMessage(bodyData.message || 'Lambda function called successfully');
      logEvent({ eventType: 'createdUserAndGroup', details: `User and group created. Message: ${bodyData.message}` });
    } catch (error) {
      setMessage(`Error creating tenant: ${error.message}`);
      logEvent({ eventType: 'errorCreatingUserAndGroup', details: `Error creating user and group: ${error.message}` });
    }
  };

  const handleClose = () => {
    navigate('/');
    logEvent({ eventType: 'closedAdminDashboard', details: 'User closed the admin dashboard' });
  };

  const handleDeleteUser = async (email) => {
    try {
      await configureLambda(jwtToken);
      const lambda = new AWS.Lambda();
      const payload = {
        'operation': 'deleteTenantUser',
        email,
        isSuperAdmin,
        tenantGroup,
      };
      const params = {
        FunctionName: 'createTenant',
        Payload: JSON.stringify(payload),
      };

      const result = await lambda.invoke(params).promise();
      const data = JSON.parse(result.Payload);
      const bodyData = JSON.parse(data.body);
      setMessage(bodyData.message || 'Lambda function called successfully');
      logEvent({ eventType: 'deletedTenantUser', details: `User deleted: ${email}` });
    } catch (error) {
      setMessage(`Error creating tenant: ${error.message}`);
      logEvent({ eventType: 'errorDeletingTenantUser', details: `Error deleting tenant user: ${error.message}` });
    }
    console.log(`Deleting user: ${email}`);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const isReadOnly = limit > loggedInUserLimit;
 
  return (
    <div className="w-full min-h-screen p-10 bg-gradient-to-r from-yellow-400 via-yellow-300 to-gray-300 flex items-center justify-center">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute w-[150%] inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative w-[150%] px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <h1 className="text-2xl font-inter font-semibold text-center mb-6">Team Dashboard</h1>
  
            {/* Only render this section if the user is a SuperAdmin */}
            {isSuperAdmin && (
              <>
                {/* Tabs */}
                <div className="flex mb-6">
                  <button
                    onClick={() => {
                      logEvent('Tab Change', 'User clicked Create Tenant tab');
                      handleTabChange('createTenant');
                    }}
                    className={`flex-1 p-2 ${activeTab === 'createTenant' ? 'bg-[#f1b20b] text-white' : 'bg-gray-200'}`}
                  >
                    Create Tenant
                  </button>
                  <button
                    onClick={() => {
                      logEvent('Tab Change', 'User clicked Add User tab');
                      handleTabChange('addUser');
                    }}
                    className={`flex-1 p-2 ${activeTab === 'addUser' ? 'bg-[#f1b20b] text-white' : 'bg-gray-200'}`}
                  >
                    Add User
                  </button>
                </div>
  
                <div className="divide-y divide-gray-200">
                  <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
  
                    {activeTab === 'createTenant' && (
                      <div className="relative space-y-8">
                      <div className="relative">
                        <input
                          type="text"
                          className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                          placeholder="Team Name"
                          value={groupName}
                          onChange={(e) => {
                            logEvent('Input Change', 'User entered team name');
                            setGroupName(e.target.value);
                          }}
                        />
                        <label className="absolute left-0 -top-3.5 text-gray-600 font-inter text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Team Name
                        </label>
                      </div>
                    
                      <div className="relative">
                        <input
                          type="number"
                          className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                          placeholder="Limit"
                          value={tenantLimit}
                          min={0}
                          max={10}
                          onChange={(e) => {
                            const value = (Math.max(0, Math.min(10, Number(e.target.value)))).toString();
                            setTenantLimit(value);
                          }}
                        />
                        <label className="absolute left-0 -top-3.5 text-gray-600 font-inter text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Set Limit
                        </label>
                      </div>
                    </div>
                    
                    )}
  
                    {activeTab === 'addUser' && (
                      <div className="relative">
                        <select
                          className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                          value={groupName}
                          onChange={(e) => {
                            logEvent('Dropdown Change', 'User selected a team');
                            setGroupName(e.target.value);
                          }}
                        >
                          <option value="">Select Team</option>
                          {userGroupNames.map((group, index) => (
                            <option key={index} value={group}>
                              {group}
                            </option>
                          ))}
                        </select>
                        <label className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Select Team
                        </label>
                      </div>
                    )}
  
                    <div className="relative">
                      <input
                        type="email"
                        className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                        placeholder="Admin Email"
                        value={adminEmail}
                        onChange={(e) => {
                          logEvent('Input Change', 'User entered admin email');
                          setAdminEmail(e.target.value);
                        }}
                      />
                      <label className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                        {activeTab === 'createTenant' ? "Admin Email" : "User Email"}
                      </label>
                    </div>
  
                    <div className="relative">
                      <input
                        type="text"
                        className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                        placeholder="Admin Email Confirm"
                        value={adminUsername}
                        onChange={(e) => {
                          logEvent('Input Change', 'User entered admin email confirm');
                          setAdminUsername(e.target.value);
                        }}
                      />
                      <label className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                        {activeTab === 'createTenant' ? "Admin Confirm Email" : "User Confirm Email"}
                      </label>
                    </div>
  
                    <div className="flex justify-between mt-6">
                      <button
                        onClick={() => {
                          logEvent('Button Click', activeTab === 'createTenant' ? 'User clicked Create Tenant' : 'User clicked Add User');
                          callLambdaFunction();
                        }}
                        className="bg-[#f1b20b] text-white rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                      >
                        {activeTab === 'createTenant' ? "Create Tenant" : "Add User"}
                      </button>
                      <button
                        onClick={() => {
                          logEvent('Button Click', 'User clicked Close');
                          handleClose();
                        }}
                        className="bg-[#f1b20b] text-white rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
  
                {message && (
                  <div className="mt-6 p-4 bg-green-100 rounded-md">
                    <p className="text-green-700">{message}</p>
                  </div>
                )}
  
                {/* Display tenant users if isSuperAdmin and activeTab !== 'addUser' */}
                {activeTab === 'createTenant' && (
                <div className="flex justify-between mt-6">
                  <button
                    onClick={() => {
                      logEvent('Button Click', 'User clicked View Users');
                      fetchTenantUsers();
                    }}
                    className="bg-[#f1b20b] text-white rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'View Users'}
                  </button>
                </div>
                )}
                {loading && (
                  <div className="mt-4">
                    <p>Loading users...</p>
                  </div>
                )}
                {tenantsUsers.length > 0 && activeTab === 'createTenant' && (
                  <div className="mt-6">
                    <h3 className="text-lg font-inter font-medium text-gray-900">Tenant Admins</h3>
                    <ul>
                      {tenantsUsers.map((user, index) => (
                        <li key={index} className="flex justify-between items-center py-2">
                          <span>{user}</span>
                          <button
                            onClick={() => {
                              logEvent('Button Click', `User clicked Delete on ${user}`);
                              handleDeleteUser(user);
                            }}
                            className="bg-red-500 text-white rounded-md px-4 py-2 hover:bg-red-600 focus:outline-none"
                          >
                            Delete
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
                
                {!isSuperAdmin && (
                <>
                  <div className="divide-y divide-gray-200">
                    <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                      
                      {isLoading ? (
                        <div className="text-blue-600">
                          {/* {console.log("Loading limit...")} */}
                          Loading limit...
                        </div>
                      ) : (
                        <>
                          {isReadOnly && (
                            <div className="text-red-600">
                              {/* {console.log("Limit exceeds 10 users. Add User button is disabled.")} */}
                              Your limit({loggedInUserLimit}) exceeds 10 users. You cannot add more users.
                            </div>
                          )}
                          {!isReadOnly && (
                            <div className="text-green-600">
                            {/* {console.log("Limit exceeds 10 users. Add User button is disabled.")} */}
                            Your limit is {loggedInUserLimit}
                          </div>
                          )}
                        
                          <div className="relative">
                            <input
                              type="email"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                              placeholder="User Email"
                              value={adminEmail}
                              onChange={(e) => {
                                setAdminEmail(e.target.value);
                                //console.log("User Email input changed:", e.target.value);
                              }}
                              disabled={isReadOnly}
                            />
                            <label className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                              User Email
                            </label>
                          </div>

                          <div className="relative">
                            <input
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                              placeholder="User Email Confirm"
                              value={adminUsername}
                              onChange={(e) => {
                                setAdminUsername(e.target.value);
                                //console.log("User Email Confirm input changed:", e.target.value);
                              }}
                              disabled={isReadOnly}
                            />
                            <label className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                              User Confirm Email
                            </label>
                          </div>

                          <div className="flex justify-between mt-6">
                            <button
                              onClick={() => {
                                callLambdaFunction();
                                //console.log("Add User button clicked");
                              }}
                              className="bg-[#f1b20b] text-white rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                              disabled={isReadOnly}
                            >
                              Add User
                            </button>
                          
                            <button
                              onClick={() => {
                                handleClose();
                                //console.log("Close button clicked");
                              }}
                              className="bg-[#f1b20b] text-white rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                            >
                              Close
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {message && (
                    <div className="mt-6 p-4 bg-green-100 rounded-md">
                      <p className="text-green-700">{message}</p>
                      {console.log("Message displayed:", message)}
                    </div>
                  )}

                  <div className="flex justify-between mt-6">
                    <button
                      onClick={() => {
                        fetchTenantUsers();
                        //console.log("View Users button clicked");
                      }}
                      className="bg-[#f1b20b] text-white rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                      disabled={loading || isLoading} // Disable while fetching users or limit
                    >
                      {loading ? 'Loading...' : 'View Users'}
                    </button>
                  </div>

                  {loading && (
                    <div className="mt-4">
                      <p>Loading users...</p>
                      {console.log("Loading users...")}
                    </div>
                  )}

                  {tenantsUsers.length > 0 && (
                    <div className="mt-6">
                      <h3 className="text-lg font-inter font-medium text-gray-900">Users</h3>
                      <ul>
                        {tenantsUsers.map((user, index) => (
                          <li key={index} className="flex justify-between items-center py-2">
                            <span>{user}</span>
                            <button
                              onClick={() => {
                                handleDeleteUser(user);
                                //console.log("Delete User button clicked for:", user);
                              }}
                              className="bg-red-500 text-white rounded-md px-4 py-2 hover:bg-red-600 focus:outline-none"
                            >
                              Delete
                            </button>
                          </li>
                        ))}
                      </ul>
                      {console.log("User list displayed:", tenantsUsers)}
                    </div>
                  )}
                </>
                )}
            </div>
          </div>
      </div>
    </div>
  );
};

export default AdminDashboard;