import React, { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import { useNavigate } from 'react-router-dom';
import { conversationState } from '../../store/atoms';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import PromptBar from './PromptBar';
import { useMediaQuery } from '@mui/material';


const LogoAndText = () => {
  return (
    <div style={{
      display: 'flex',
      padding: '50px 10px',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '5px',
      flex: '1 0 0',
      alignSelf: 'stretch',
    }}>
      <img 
        src="logoandtext.png" 
        alt="Logo and Text" 
        style={{
          width: '203px',
        }}
      />
    </div>
  );
};

const Sparkles = () => {
  const [showFirst, setShowFirst] = useState(true);
  const navigate = useNavigate();
  const [conversation, setConversation] = useRecoilState(conversationState);
  const [selectedQuery, setSelectedQuery] = useState('');
  const isMobile = useMediaQuery('(max-width:768px)');


  useEffect(() => {
    if (conversation.length > 0) {
      navigate('/targetPage');
    }
  }, [conversation, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirst(prev => !prev);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
    
  return (
    <div className="bg-white text-black min-h-screen flex w-full overflow-hidden">
      {/* <Sidebar_v2 /> */}
      <div className='max-w-full w-full'>
        <main className="flex-1 flex flex-col justify-between items-center max-w-[960px] mx-auto w-full h-screen">
        {!isMobile &&
        <div className="flex flex-col items-center gap-5 w-[203px] mx-auto mt-12">
              <div className="w-[60px] h-[60px] flex-shrink-0 relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                  <circle cx="30" cy="30" r="30" fill="#EBAE12"/>
                </svg>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="28.749" 
                  height="27.454" 
                  viewBox="0 0 32 30" 
                  fill="none"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  <circle cx="13.8434" cy="13.4684" r="12.2184" stroke="white" stroke-width="2.5"/>
                  <path d="M23.043 21.3733L30.374 28.7043" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                </svg>
              </div>
              <p className="text-black font-inter text-base font-normal leading-[120%] self-stretch text-center">
                Ask a query to get started!
              </p>
            </div>
        }
          <div className="flex justify-center items-center h-screen">
        <div className='relative'>
          <img 
            src="loader.gif" 
            alt="Loading..." 
            style={{
              width: '404px',  // Match previous SVG size
              height: '54.396px'  // Match previous SVG size
            }}
          />
        </div>
      </div>

       
        
      <div className="w-full max-w-[960px] mb-6 sm:mb-8 pb-4 sm:pb-6">
                <PromptBar
                  selectedQuery={selectedQuery}
                  onQuerySubmit={(query) => {
                  }}
                />
              </div>
        </main>
      </div>
    </div>
  );
};

export default Sparkles;