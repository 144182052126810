import React, { useState, useEffect } from 'react';
import { conversationState } from "../../store/atoms";
import './Home.css';
import CurrentCoversation from './CurrentConversation';
import { Amplify } from 'aws-amplify';
import { useRecoilValue, useRecoilState } from 'recoil';
import TargetedSearch from './TargetedSearch';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import PromptBar from "./PromptBar";
import { logEvent } from '../../cloudwatchLogger';  // Import the logEvent function
import useLambda from '../hooks/useLambda';
import { awsExports } from '../../aws-exports';
import AWS from 'aws-sdk';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import {conversationIdState} from '../../store/atoms'

Amplify.configure(awsExports);


const TargetPage = () => {
  const conversation = useRecoilValue(conversationState);
  const [selectedQuery, setSelectedQuery] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [decodedToken, setDecodedToken] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  
  //console.log('isMobile: '+isMobile);

  const { configureLambda, decodeToken, getJWT } = useLambda();


  const fetchRoles = async () => {
    //setIsLoading(true);
    try {
      const token = await getJWT();
      //console.log('jwt token:'+token);
      setJwtToken(token);
      await configureLambda(token);
      //console.log('lambda is configured')
      const decodedToken = decodeToken(token);
      //console.log('decoded token is: '+JSON.stringify(decodedToken));
      setDecodedToken(decodedToken);
      //console.log('decoded token is in prompt bar: '+JSON.stringify(decodeToken));
    } catch (error) {
      //console.error('Failed to fetch roles:', error);
      logEvent({ eventType: 'fetchRolesError', details: `Error fetching roles: ${error.message}` });
    }
  };

  useEffect(() => {
    fetchRoles();
    //setConversationId('');
  }, []);

  useEffect(() => {
    const storedQuery = localStorage.getItem('selectedQuery');
    if (storedQuery) {
      setSelectedQuery(storedQuery);
      localStorage.removeItem('selectedQuery');
      logEvent({ eventType: 'storedQueryLoaded', details: `Loaded stored query: ${storedQuery}` });
    }
    
    // Add event listener for popstate
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      //logEvent({ eventType: 'componentUnmounted', details: 'TargetPage component unmounted' });
    };
  }, []);

  const handlePopState = () => {
    if (conversation.length > 0) {
      // If there's a conversation, clear it and show TargetedSearch
      // You might need to implement a function to clear the conversation state
      // clearConversation();
      navigate('/targetPage', { replace: true });
    }
  };

  useEffect(() => {
    if (location.state?.from === 'search' && conversation.length === 0) {
      navigate('/targetPage', { replace: true });
    }
  }, [location, conversation, navigate]);


  useEffect(() => {
    //logEvent({ eventType: 'conversationStateChanged', details: `Conversation length: ${conversation.length}` });
  }, [conversation]);

  const handleQuerySelect = (query) => {
    setSelectedQuery(query);
    logEvent({ eventType: 'querySelected', details: `Selected query: ${query}` });
  };

  const themeStyles = {
    backgroundColor: '#FFF',
    color: '#000',
    minHeight: '100vh',
    display: "flex",
    width: "100%"
  };

  const toggleSidebar = () => {
    logEvent({ action: 'SidebarToggled', message: `Sidebar ${isSidebarCollapsed ? 'expanded' : 'collapsed'}` });
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className={`bg-white text-black ${isMobile ? 'fixed' : 'min-h-screen'} flex w-full overflow-hidden`}>
      {/* <Sidebar_v2 isCollapsed={isSidebarCollapsed}/> */}
      <div className='max-w-full w-full'>
        <main className={`flex-1 ${isMobile ? 'h-full' : ''}`}>
        {/* mx-auto px-4 sm:px-6 lg:px-8 pt-2 sm:pt-4 pb-8 */}
          <div className={`max-w-full flex flex-col ${isMobile ? 'h-[90vh]' : 'h-screen'}`}>
            {conversation.length === 0 ? (
              <TargetedSearch
              selectedQuery={selectedQuery}
              setSelectedQuery={handleQuerySelect}
              />
            ) : (
                <>
  		            {/* <div className="flex-1 overflow-y-auto mb-6">  */}
                  <div className={`flex-1 ${isMobile ? 'overflow-y-auto' : ''} mb-6`}>
                    <CurrentCoversation />
                  </div>
                  <div className={`pb-10 ${isMobile ? 'mt-auto' : ''}`}>
                    <PromptBar
                      selectedQuery={selectedQuery}
                      onQuerySubmit={(query) => {
                        logEvent({ eventType: 'querySubmitted', details: `Submitted query: ${query}` });
                      }}
                    />
                  </div>
                </>
              )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default TargetPage;