import React, { useState, useEffect, useRef } from 'react';
import { Users, FileQuestion, Target, Clock, Pin, UserPlus, ChevronDown, ChevronRight, Search, PinOff, FolderPlus } from 'lucide-react';
import { useRecoilState } from 'recoil';
//import { logEventsState } from '../../store/atoms';
import PromptBar from './PromptBar';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import { pinnedQueriesState } from '../../store/atoms';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { logEvent } from '../../cloudwatchLogger';
import config from '../../config';
import useLambda from '../hooks/useLambda';
import AWS from 'aws-sdk';
import { awsExports } from '../../aws-exports';
import { useMediaQuery } from '@mui/material';
import { conversationIdState } from '../../store/atoms';

// Lambda invoker utility
const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  //console.log('action is : '+action);
  const params = {
    FunctionName: lambdaFunctionName,
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    //console.log('result from the lambda is: '+JSON.stringify(result.data));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};

  const TargetedSearch = () => {
    const [selectedQuery, setSelectedQuery] = useState('');
    const pinnedQueries = useRecoilValue(pinnedQueriesState);
    const setPinnedQueries = useSetRecoilState(pinnedQueriesState);
    const [managedQueries, setManagedQueries] = useState([]);
    const [tenantGroup, setTenantGroup] = useState([]);
    const [loading, setLoading] = useState(true);
    const [jwtToken, setJwtToken] = useState('');
    const [sub, setSub] = useState('');
    const { decodeToken, getJWT } = useLambda();
    const pageRef = useRef(null);
    const [lambdaFunctionName] = useState(awsExports.Lambda.queryManagement);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isTenantAdmin, setIsTenantAdmin] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)');

    const [conversationId, setConversationId] = useRecoilState(conversationIdState);

  
    useEffect(() => {
      const fetchTokenAndSub = async () => {
        try {
          const token = await getJWT();
          setJwtToken(token);
          const decodedToken = decodeToken(token);
          const tenantgroup = decodedToken['cognito:groups'];
          const cognitoRoles = decodedToken['cognito:roles'];
          let filteredGroups = tenantgroup.includes("Tenants") ? tenantgroup.filter(group => group !== 'Tenants') : [...tenantgroup];
          if (cognitoRoles) {
            setIsSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
            setIsTenantAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
          }
          setTenantGroup(filteredGroups);
          setSub(decodedToken['sub']);
        } catch (error) {
          console.error('Failed to fetch token:', error);
          logEvent({ eventType: 'fetchTokenError', details: `Error fetching token: ${error.message}` });
        }
      };
  
      fetchTokenAndSub();
      setConversationId('');
    }, []);
  
    useEffect(() => {
      const fetchSavedQueries = async () => {
        if (!sub) return;
        try {
          const lambda = new AWS.Lambda();
          const data = await invokeLambda('getSavedQueries', { 'created_by':sub }, lambda, lambdaFunctionName);
          setManagedQueries(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching saved queries:', error);
          setLoading(false);
        }
      };
  
      const fetchUserSavedQueries = async () => {
        //console.log("inside fetch user saved queries");
        if (!tenantGroup) return;
        try {
          //console.log(`tenant group inside fetch user group is : ${tenantGroup}`);
          const lambda = new AWS.Lambda();
          const data = await invokeLambda('getUserSavedQueries', { 'groupname':tenantGroup[0] }, lambda, lambdaFunctionName);
          setManagedQueries(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching saved queries:', error);
          setLoading(false);
        }
      };
  
      if (isSuperAdmin || isTenantAdmin) {
        fetchSavedQueries();
      } else if (tenantGroup.length > 0) {
        fetchUserSavedQueries();
      }
    }, [sub, isSuperAdmin, isTenantAdmin, tenantGroup]);
  
// Function to handle dynamic transformation
const transformDatabaseResults = (dbResults) => {
  const transformedResults = dbResults.map((result, index) => ({
    text: result.query_name,
    isPinned: true,
    id: result.id
  }));
  setPinnedQueries(transformedResults);
};
  const handleQueryClick = (query) => {
    setSelectedQuery(query);
    logEvent({ eventType: 'querySelected', details: `Selected query: ${query}` });
  };

  const handlePinToggle = (id) => {
    const queryToToggle = pinnedQueries.find(query => query.id === id);
    if (!queryToToggle) return;
    if (queryToToggle.isPinned) {
      // Unpin the query and call delete function
      deleteQuery(id);
    }
  }

  const deleteQuery = async (id) => {
    console.log(`Deleting query with id: ${id}`);
  
    const updatedQueries = pinnedQueries.filter((query) => query.id !== id);
    setPinnedQueries(updatedQueries);
    const lambda = new AWS.Lambda();
    const data = await invokeLambda('deletePrompts',{ 'id':id }, lambda, lambdaFunctionName);
        // setAvailableQueries(data);
    //console.log('print all prompts'+ JSON.stringify(data));
  
    setTimeout(() => {
      logEvent({ eventType: 'queryUnpinned', details: `Unpinned query with id: ${id}` });
    }, 0);
  };

  const scrollToBottom = () => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };


  const pinnedQueryCards = pinnedQueries.filter(query => query.isPinned);

  useEffect(() => {
    const fetchPrompts = async () => {
      //console.log("this is sub:" + sub)
      if (!sub) return;
      try {
        const lambda = new AWS.Lambda();
        const data = await invokeLambda('getPrompts',{ 'created_by':sub }, lambda, lambdaFunctionName);
        // setAvailableQueries(data);
        //console.log('print all prompts'+ JSON.stringify(data));
        transformDatabaseResults(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching queries:', error);
        setLoading(false);
      }
    };
    fetchPrompts()
    }, [sub]);

    function SuggestionCard({ data }) {
      return (
        <div
          className="flex flex-col flex-1 shrink self-stretch p-4 my-auto bg-white rounded-[14px] border border-solid border-[#D9D9D9] shadow-[2px_2px_8px_0px_rgba(0,0,0,0.10)] cursor-pointer transition-all duration-300 hover:bg-[#FDF7E7] hover:border-[#EBAE12] group"
          onClick={() => handleQueryClick(data.text)}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              handlePinToggle(data.id);
            }}
            className="focus:outline-none p-0 w-6 h-6 flex items-center justify-center"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="20"
              height="21" 
              viewBox="0 0 20 21" 
              fill="gray" 
              stroke="currentColor"
            >
              <path 
                d="M14.0324 1.35398L19.146 6.46762C20.5525 7.87405 20.1677 10.245 18.3887 11.1346L13.2272 13.7153C13.0414 13.8082 12.8993 13.9702 12.8315 14.1667L11.3064 18.5814C10.9869 19.5062 9.80961 19.7773 9.11777 19.0856L5.8281 15.7958L1.12401 20.5H0L8.48872e-05 19.376L4.70417 14.6719L1.4145 11.3822C0.722682 10.6904 0.993857 9.5131 1.91861 9.19362L6.33341 7.66853C6.52981 7.60068 6.69182 7.45863 6.78475 7.27277L9.36541 2.11133C10.255 0.332306 12.626 -0.0524538 14.0324 1.35398ZM18.0221 7.59155L12.9085 2.47791C12.2692 1.83862 11.1914 2.01352 10.7871 2.82216L8.20647 7.9836C7.92767 8.54116 7.44161 8.96738 6.8524 9.17094L2.83787 10.5577L9.94228 17.6621L11.3292 13.6477C11.5327 13.0584 11.9589 12.5724 12.5164 12.2936L17.6778 9.71284C18.4865 9.30859 18.6614 8.23084 18.0221 7.59155Z" 
                strokeWidth="1"
              />
            </svg>
          </button>
          <p className="mt-4 font-inter transition-colors duration-300 group-hover:text-[#EBAE12]">{data.text}</p>
        </div>
      );
    }
  
    return (
      <div className="bg-white text-black h-full flex w-full pb-[40px]">
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-[40px]">
          <div className={`flex flex-col ${isMobile ? 'h-full' : 'h-screen'}`}>
            <main className="flex-1 flex flex-col justify-between items-center max-w-[960px] mx-auto w-full">
              {!isMobile && (
                <div className="pt-10 mt-0">
                  <div className="flex flex-col items-center gap-5 w-[203px] mx-auto">
                    <div className="w-[60px] h-[60px] flex-shrink-0 relative">
                      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <circle cx="30" cy="30" r="30" fill="#EBAE12"/>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.749"
                        height="27.454"
                        viewBox="0 0 32 30"
                        fill="none"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      >
                        <circle cx="13.8434" cy="13.4684" r="12.2184" stroke="white" strokeWidth="2.5"/>
                        <path d="M23.043 21.3733L30.374 28.7043" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
                      </svg>
                    </div>
                    <p className="text-black font-inter text-base font-normal leading-[120%] self-stretch text-center">
                      Ask a query to get started!
                    </p>
                  </div>
                </div>
              )}
  
              <div className="flex-1 flex items-center justify-center w-full">
                <section
                  className={`
                    ${isMobile
                      ? 'grid grid-cols-2 gap-x-3 gap-y-5 w-full max-w-md'
                      : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 w-full max-w-4xl'
                    }
                    mx-auto my-6 sm:my-8 text-sm leading-4 text-neutral-400
                  `}
                >
                  {pinnedQueries.map((query) => (
                    <SuggestionCard key={query.id} data={query}/>
                  ))}
                </section>
              </div>
  
              {/* Modified PromptBar container with explicit padding bottom */}
              <div className="w-full max-w-[960px] mb-[40px]">
                <PromptBar
                  selectedQuery={selectedQuery}
                  onQuerySubmit={(query) => {
                    logEvent({ eventType: 'querySubmitted', details: `Submitted query: ${query}` });
                  }}
                />
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  };
  
  export default TargetedSearch;