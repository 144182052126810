import React, { useState, useEffect } from 'react';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import WelcomeHeader from './WelcomeHeader';
import { Eye, EyeOff } from 'lucide-react';
import { updatePassword } from 'aws-amplify/auth';
import { useMediaQuery } from '@mui/material';

const SkeletonLoading = () => {
  const loadingGradient = 'linear-gradient(90deg, #E6E6E6 0%, #D9D9D9 100%)';
  
  return (
    <div className="flex flex-col items-start w-[773px] py-10 pl-[52px] gap-2.5">
      {/* Title skeleton */}
      <div 
        className="h-8 w-[180px]" 
        style={{ 
          borderRadius: '7px',
          background: loadingGradient
        }} 
      />
      
      {/* Description skeleton */}
      <div 
        className="h-5 w-[480px] mt-2" 
        style={{ 
          borderRadius: '7px',
          background: loadingGradient
        }} 
      />
      
      {/* Divider skeleton */}
      <div className="w-full h-[1px] my-8 bg-[#E6E6E6]" />
      
      {/* Section title skeleton */}
      <div 
        className="h-6 w-[140px] mb-6" 
        style={{ 
          borderRadius: '7px',
          background: loadingGradient
        }} 
      />
      
      {/* Settings items */}
      <div className="w-full space-y-4">
        {/* First row */}
        <div className="flex items-center justify-between">
          <div className="space-y-2">
            <div 
              className="h-5 w-[320px]" 
              style={{ 
                borderRadius: '7px',
                background: loadingGradient
              }} 
            />
            <div 
              className="h-4 w-[520px]" 
              style={{ 
                borderRadius: '7px',
                background: loadingGradient
              }} 
            />
          </div>
        </div>
        
        {/* Second row */}
        <div className="flex items-center justify-between">
          <div className="space-y-2">
            <div 
              className="h-5 w-[280px]" 
              style={{ 
                borderRadius: '7px',
                background: loadingGradient
              }} 
            />
            <div 
              className="h-4 w-[480px]" 
              style={{ 
                borderRadius: '7px',
                background: loadingGradient
              }} 
            />
          </div>
        </div>
        
        {/* Third row */}
        <div className="flex items-center justify-between">
          <div className="space-y-2">
            <div 
              className="h-5 w-[360px]" 
              style={{ 
                borderRadius: '7px',
                background: loadingGradient
              }} 
            />
            <div 
              className="h-4 w-[540px]" 
              style={{ 
                borderRadius: '7px',
                background: loadingGradient
              }} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ToggleSwitch = ({ isOn, handleToggle }) => {
  const switchOnPath = 'switchon.png';
  const switchOffPath = 'switchoff.png';

  return (
    <div onClick={handleToggle} className="cursor-pointer">
      <img
        src={isOn ? switchOnPath : switchOffPath}
        alt={isOn ? "Switch On" : "Switch Off"}
        className="w-14 h-8 object-contain"
      />
    </div>
  );
};

const PasswordChangeSuccess = () => {
  return (
    <div className="flex w-[37.5rem] p-4 flex-col items-start gap-2.5 rounded-[0.8125rem] border-2 border-[#40C5AF] bg-[#ECF9F7] shadow-[2px_2px_8px_0px_rgba(64,197,175,0.10)]">
      <div className="flex items-center gap-2.5">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986" stroke="#40C5AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22 4L12 14.01L9 11.01" stroke="#40C5AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span className="text-[#40C5AF] font-inter text-base font-bold leading-[120%]">
          Password changed
        </span>
      </div>
      <p className="self-stretch text-[#40C5AF] font-inter text-base font-normal leading-[120%]">
        Your password has been successfully changed
      </p>
    </div>
  );
};

const PasswordChangeModal = ({ isOpen, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [showPasswords, setShowPasswords] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  const resetForm = () => {
    setFormData({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
    setShowPasswords({
      oldPassword: false,
      newPassword: false,
      confirmPassword: false
    });
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleUpdatePassword = async (oldPassword, newPassword) => {
    try {
      await updatePassword({ oldPassword, newPassword });
      console.log('Password updated successfully');
      // Show success message to user
      onSuccess();
      // alert('Password updated successfully');
      handleClose();
    } catch (error) {
      console.error('Error updating password:', error);
      // Show error message to user
      if (error.name === 'NotAuthorizedException') {
        alert('Old password is incorrect');
      } else {
        alert('Error updating password. Please try again.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      alert('New passwords do not match');
      return;
    }
    await handleUpdatePassword(formData.oldPassword, formData.newPassword);
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="inline-flex flex-col items-start p-5 gap-5 bg-white rounded-[14px] border border-[#F2F2F2] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.10)] relative w-[400px]">
        {/* Header with lock icon and title */}
        <div className="flex items-center gap-2 w-full">
          <div className="flex items-center gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h2 className="text-lg font-bold text-black">Change password</h2>
          </div>
          <button 
            onClick={handleClose}
            className="ml-auto text-gray-500 hover:text-gray-700"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        <div className="w-full" style={{ borderBottom: '1px solid #d3d3d3' }}></div>
        {/* Form */}
        <form onSubmit={handleSubmit} className="w-full space-y-5">
          <div className="flex flex-col gap-5">
            {/* Old Password Input */}
            <div className="relative flex flex-col">
              {/* <label className="text-sm text-gray-500 mb-1">
                Enter old password
              </label> */}
              <div className="relative">
                <input

                  type={showPasswords.oldPassword ? "text" : "password"}
                  value={formData.oldPassword}
                  onChange={(e) => setFormData({...formData, oldPassword: e.target.value})}
                  className="w-full px-3 pt-5 pb-2 text-sm border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                />
                <label
                  className={`
                    absolute text-xs text-gray-500 left-3 top-1
                    transition-all duration-200
                  `}
                >
                  Enter old password
                </label>
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('oldPassword')}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 focus:outline-none"
                >
                  {showPasswords.oldPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                </button>
              </div>
            </div>
            
            {/* New Password Input */}
            <div className="relative flex flex-col">
              {/* <label className="text-sm text-gray-500 mb-1">
                Please enter new password
              </label> */}
              <div className="relative">
                <input
                  type={showPasswords.newPassword ? "text" : "password"}
                  value={formData.newPassword}
                  onChange={(e) => setFormData({...formData, newPassword: e.target.value})}
                  className="w-full px-3 pt-5 pb-2 text-sm border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                />
                <label
                  className={`
                    absolute text-xs text-gray-500 left-3 top-1
                    transition-all duration-200
                  `}
                >
                  Please enter new password
                </label>
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('newPassword')}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 focus:outline-none"
                >
                  {showPasswords.newPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                </button>
              </div>
            </div>
            
            {/* Confirm Password Input */}
            <div className="relative flex flex-col">
              {/* <label className="text-sm text-gray-500 mb-1">
                Please re-enter new password
              </label> */}
              <div className="relative">
                <input
                  type={showPasswords.confirmPassword ? "text" : "password"}
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
                  className="w-full px-3 pt-5 pb-2 text-sm border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                />
                <label className={`
                    absolute text-xs text-gray-500 left-3 top-1
                    transition-all duration-200
                  `}
                >
                  Please re-enter new password
                </label>
                  
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('confirmPassword')}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 focus:outline-none"
                >
                  {showPasswords.confirmPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                </button>
              </div>
            </div>
          </div>
          <div className="w-full" style={{ borderBottom: '1px solid #d3d3d3' }}></div>
          <div className="flex justify-end w-full">
            <button
              type="submit"
              className="flex w-[166px] h-[39px] px-[14px] py-1.5 items-start gap-1 rounded-[7px] bg-[#EBAE12] text-white font-bold justify-center hover:bg-[#d69e10] transition-colors"
            >
              Update password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const SettingsPage = () => {
  const [loading, setLoading] = useState(true);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [initialNotificationState, setInitialNotificationState] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [showPasswordChangeSuccess, setShowPasswordChangeSuccess] = useState(false);

  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    const loadData = async () => {
      try {
        const savedState = localStorage.getItem('notificationsEnabled');
        if (savedState !== null) {
          const parsedState = JSON.parse(savedState);
          setNotificationsEnabled(parsedState);
          setInitialNotificationState(parsedState);
        }
        // Simulate API delay
        await new Promise(resolve => setTimeout(resolve, 1500));
        setLoading(false);
      } catch (error) {
        console.error('Error loading settings:', error);
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    setIsSaveEnabled(notificationsEnabled !== initialNotificationState);
  }, [notificationsEnabled, initialNotificationState]);

  const handleNotificationsToggle = () => {
    setNotificationsEnabled(prevState => !prevState);
  };

  const handleSaveSettings = () => {
    localStorage.setItem('notificationsEnabled', JSON.stringify(notificationsEnabled));
    setInitialNotificationState(notificationsEnabled);
    setIsSaveEnabled(false);
    alert('Settings saved!');
  };

  const handlePasswordChangeSuccess = () => {
    setShowPasswordChangeSuccess(true);
    setTimeout(() => setShowPasswordChangeSuccess(false), 3000); // Hide after 5 seconds
  };

  return (
    <div className="flex h-screen bg-white">
      {/* <Sidebar_v2 /> */}
      {loading ? (
        <SkeletonLoading />
      ) : (
        <div className="flex-1 flex flex-col overflow-hidden">
          <WelcomeHeader />
          <main className="flex flex-col gap-10 flex-1 overflow-y-auto p-8">
          {showPasswordChangeSuccess && <PasswordChangeSuccess />}
            <div className="flex justify-between items-center self-stretch">
              <div className="flex flex-col items-start gap-[0.375rem] flex-1 flex-shrink-0">
                <h1 className="text-[#000] font-inter text-[1.5625rem] font-bold leading-[120%] self-stretch">
                  Settings
                </h1>
                <p className="text-[#000] font-inter text-base font-normal leading-[120%]">
                  Various settings can be managed below according to your preferences and requirements.
                </p>
              </div>
            </div>

            <div className='flex flex-col items-start gap-5'>
              <div className={`flex ${isMobile ? 'w-full' : 'w-[55rem]'} flex-col items-start gap-5`}>
                <div className='flex pt-5 justify-between items-center self-stretch border-t border-[#D9D9D9]'>
                  <p className="text-[#000] font-inter text-base font-bold leading-[120%]">
                    Notification
                  </p>
                </div>
              </div>
              <div className={`flex ${isMobile ? 'w-full' : 'w-[55rem]'} justify-between items-center self-stretch`}>
                <div className="flex w-[11.875rem] flex-col items-start gap-0.5">
                  <p className="self-stretch text-black font-inter text-base font-normal leading-[120%]">
                    Notifications
                  </p>
                  <p className="text-[#6D7280] font-inter text-[0.8125rem] font-normal leading-[120%]" style={{ whiteSpace: 'nowrap' }}>
                    Please enable browser notification
                  </p>
                </div>
                <div className="flex flex-col justify-center items-start gap-2">
                  <ToggleSwitch
                    isOn={notificationsEnabled}
                    handleToggle={handleNotificationsToggle}
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col items-start gap-5'>
                <div className={`flex ${isMobile ? 'w-full' : 'w-[55rem]'} flex-col items-start gap-5`}>
                <div>
                  <p className="text-[#000] font-inter text-base font-bold leading-[120%]">
                    Password
                  </p>
                </div>
              </div>
              <div className={`flex  ${isMobile ? 'w-full' : 'w-[55rem]'} justify-between items-center self-stretch`}>
                <div className="flex w-[11.875rem] flex-col items-start gap-0.5">
                  <p className="self-stretch text-black font-inter text-base font-normal leading-[120%]">
                    Change Password
                  </p>
                  <p className={`text-[#6D7280] font-inter text-[0.8125rem] font-normal leading-[120%] ${isMobile ? '' : 'whitespace-nowrap'}`}>
                    The new password should be reset to one that is different from the previous one
                  </p>
                </div>
                <div 
                  className="flex flex-col justify-center items-start gap-2 cursor-pointer" 
                  onClick={() => setIsPasswordModalOpen(true)}
                >
                  <div style={{ width: '24px', height: '24px', transform: 'rotate(-360deg)' }}>
                    <img src="./changepwdicon.svg" alt="Change Password Icon" width="24" height="25" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-start gap-2.5">
              <button
                onClick={handleSaveSettings}
                disabled={!isSaveEnabled}
                className={`
                  flex justify-center items-center
                  py-2.5 px-3.5
                  gap-1
                  rounded-[7px]
                  font-inter text-base font-bold leading-[120%]
                  ${
                    isSaveEnabled
                      ? 'bg-[#ebae12] text-white hover:bg-white hover:text-[#ebae12] border-2 border-[#ebae12]'
                      : 'bg-[rgb(204,204,204)] text-[rgba(142,142,142,255)] cursor-not-allowed border-2 border-[rgb(204,204,204)]'
                  }
                `}
              >
                Save Changes
              </button>
            </div>
          </main>

          {/* Add the PasswordChangeModal component here */}
          <PasswordChangeModal 
            isOpen={isPasswordModalOpen}
            onClose={() => setIsPasswordModalOpen(false)}
            onSuccess={handlePasswordChangeSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default SettingsPage;